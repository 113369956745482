// 職員カレンダー設定
export const COLLECTION_STAFF_CALENDAR_SETTING = "staff-calendars";
// 職員カレンダー設定
export const COLLECTION_STAFF_CALENDAR_SET_COLORS =
  "staff-calendars-set-colors";
// 訪問へ行く 一時保存データ
export const COLLECTION_VISIT_TEMP = "visit-temp-datas";
// 利用者一覧 検索条件
export const COLLECTION_SEARCH_COND_PATIENT_LIST =
  "patientlist-search-conditions";
// 訪問へ行く 検索条件
export const COLLECTION_SEARCH_COND_VISIT_LIST = "visitlist-search-conditions";
// 記録を提出 検索条件
export const COLLECTION_SEARCH_COND_RECORD2_LIST =
  "record2list-search-conditions";
// 24時間体制 検索条件（利用者一覧）
export const COLLECTION_SEARCH_COND_HOUR24_LIST =
  "hour24list-search-conditions";
// ホーム アイテムリスト
export const COLLECTION_HOME_ITEM_LIST = "home-items";
// 共有ファイル登録 設定
export const COLLECTION_SHARE_FILE_SETTING = "share-file-settings";
// 共有ファイル(利用者) 検索条件
export const COLLECTION_PATIENT_SHARE_FILE_SETTING =
  "patient-share-file-settings";
// 24時間体制 検索条件
export const COLLECTION_SEARCH_COND_FULLTIME =
  "collection-search-cond-fulltime";
// 利用者 お気に入り情報
export const COLLECTION_FAVORITED_PATIENT_IDS = "favorited-patient-ids";
// 利用者 カレンダー 表示設定
export const COLLECTION_PATIENT_CALENDAR_DISPLAY = "patient-calendar-display";
// レセプト確定状況一覧情報
export const COLLECTION_RECEIPT_LIST_ITEMS = "receipt-list-items";
// 訪問予定管理 絞り込み条件
export const COLLECTION_SCHEDULE_SEARCH = "schedule-search";
// サポート 検索条件
export const COLLECTION_SUPPORT_SEARCH = "support-search";
// 帳票一覧 表示列設定
export const COLLECTION_REPORTLIST_COLUMN_DISPLAY = "reportlist-column-display";
// 帳票 緊急連絡先リスト 前回出力日時
export const COLLECTION_EMERGENCY_CONTACT_LIST_LAST_OUTPUT_DATETIME =
  "emergency-contact-list-last-output";
// 「iBow AI自動作成」作成履歴
export const COLLECTION_AI_GENERATE_STATE = "generate-state";
// 「iBow AI自動作成」作成オプション
export const COLLECTION_IBOW_AI_GENERATE_OPTION = "ibow-ai-generate-option";
// お知らせ設定
export const COLLECTION_BBS_SETTING = "bbs-setting";
/** 傷病名付検索履歴 */
export const COLLECTION_DISEASE_HISTORY = "disease-history";
// レセプト事前チェック
export const COLLECTION_RECEIPT_PRE_CHECK = "pre-check-same-building-count";
