



























import { Component } from "vue-property-decorator";
import ContactNoticeList from "@/components/contact/ContactNoticeList.vue";
import ContactInfo from "@/components/contact/ContactInfo.vue";
import FAQList from "@/components/contact/FAQList.vue";
import { Inquiry, DEFAULT_INQUIRY, InquiryAnswer } from "#/model/inquiry";
import { BreadItem } from "@/store/modules/patient/types";
import ContactUtility from "@/views/contact/utility";

@Component({
  components: { ContactNoticeList, FAQList, ContactInfo }
})
export default class ContactDetail extends ContactUtility {
  /** 初回問合せ */
  private inquiry: Inquiry = { ...DEFAULT_INQUIRY };
  /** 表示する問合せ情報一覧 */
  private inquiries: Inquiry[] = [];

  private breadItems: BreadItem[] = [];

  /** 問合せアンケート回答後ポップアップ表示制御 */
  private questionnairePopup = false;

  private setBreadItems() {
    const title = this.removeHTMLTag(this.inquiry.contact);

    this.breadItems = [
      {
        text: "サポート",
        disabled: false,
        to: "/contact",
        exact: true
      },
      {
        text: "お問合せ一覧",
        disabled: false,
        to: "/contact/history",
        exact: true
      },
      {
        text: title.length > 20 ? title.slice(0, 20) + "..." : title,
        disabled: true,
        to: ""
      }
    ];
  }

  public async created() {
    await this.getInquiry();
    this.markInquiry();
  }

  // 問合せ取得
  private async getInquiry() {
    //todo エラーの場合、resolveされない。。
    return new Promise(resolve => {
      const inquiryId = Number(this.$route.params.id);

      this.postJsonCheck(
        window.base_url + "/api/inquiry/get",
        { ids: [inquiryId] },
        res => {
          const parentInquiry = res.data.parent_inquiry;
          const inquiries = res.data.inquirys;

          // メタデータ削除
          parentInquiry.contact = this.trimMetaData(parentInquiry.contact);
          this.inquiry = parentInquiry;

          this.inquiries = inquiries.map((inquiry: Inquiry) => {
            inquiry.contact = this.trimMetaData(inquiry.contact);
            return inquiry;
          });

          this.setBreadItems();
          resolve(0);
        }
      );
    });
  }

  /** 既読処理 */
  private markInquiry() {
    // 未読の問合せがなければ、既読処理を行わない
    if (!this.checkHasNotReadingInquiry()) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/inquiry/mark",
      { inquiry: this.inquiry },
      () => {
        // データ更新
        this.inquiry.is_not_reading = 0;
        this.inquiries = this.inquiries.map((inquiry: Inquiry) => {
          inquiry.is_not_reading = 0;
          return inquiry;
        });
      }
    );
  }

  /** CSアンケート回答 */
  private async answerQuestionnaire(inquiryAnswer: InquiryAnswer) {
    if (
      !(await this.$openConfirm(
        "入力した内容で評価を送信します。\nよろしいですか？"
      ))
    ) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/inquiry/questionnaire/answer",
      {
        inquiry_answer: inquiryAnswer
      },
      async () => {
        await this.getInquiry();

        // 回答エリア上部までスクロール
        const targetElement = document.getElementById(
          `inquiry-answer-${inquiryAnswer.id}`
        );
        const headerOffset = 86;

        if (targetElement) {
          const elementPosition =
            targetElement.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }

        // スナックバー表示
        this.questionnairePopup = true;
      }
    );
  }

  /** 追加問合せ画面へ遷移 */
  private sendInquiry() {
    this.$router.push({ path: `/contact/new/${this.inquiry.id}` });
  }

  /** 一連の問合せが未読状態か判定 */
  private checkHasNotReadingInquiry(): boolean {
    if (this.inquiry.is_not_reading) {
      return true;
    }

    for (const subInquiry of this.inquiry.sub_inquiries) {
      if (subInquiry.is_not_reading) {
        return true;
      }
    }

    return false;
  }
}
