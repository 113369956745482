







































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { Inquiry, DEFAULT_INQUIRY, InquiryAnswer } from "#/model/inquiry";
import UtilMixin from "@/mixins/utilMixin";
import ContactView from "@/components/contact/ContactView.vue";
import { INQUIRY_STATUS, INQUIRY_STATUS_COLOR } from "@/const/contact";

@Component({
  components: {
    ContactView
  }
})
export default class ContactInfo extends Mixins(UtilMixin) {
  /** 親問合せ */
  @Prop({ default: DEFAULT_INQUIRY }) parentInquiry!: Inquiry;
  /** 詳細表示する問合せ一覧 */
  @Prop({ default: () => [] }) inquiries!: Inquiry[];

  /** 問合せステータス定数 */
  private INQUIRY_STATUS = INQUIRY_STATUS;
  /** 問合せステータス色定数 */
  private INQUIRY_STATUS_COLOR = INQUIRY_STATUS_COLOR;

  /** 追加問合せ送信 */
  @Emit("send:inquiry")
  private sendInquiry() {
    return;
  }

  /** CSアンケート回答 */
  @Emit("click:answer")
  private answerQuestionnaire(inquiryAnswer: InquiryAnswer[]) {
    return inquiryAnswer;
  }
}
