



























































































import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import { VForm } from "@/types";
import { InquiryAnswer, DEFAULT_INQUIRY_ANSWER } from "#/model/inquiry";
import UtilMixin from "@/mixins/utilMixin";

@Component({})
export default class InquiryQuestionnaireForm extends Mixins(UtilMixin) {
  /** Ref */
  @Ref("form") private readonly form!: VForm;

  /** Prop */

  /** 回答 */
  @Prop({ default: () => DEFAULT_INQUIRY_ANSWER })
  inquiryAnswer!: InquiryAnswer;

  /** 変数 */

  /** getter */

  /** 回答するボタン非活性チェック */
  private get IsAnswerButtonDisabled(): boolean {
    let isChecked = false;

    this.inquiryAnswer.questionnaire_answers.forEach(questionAnswer => {
      if (questionAnswer.question_type != 1) {
        return;
      }
      questionAnswer.selections.forEach(selectionAnswer => {
        if (selectionAnswer.is_checked == 1) {
          isChecked = true;
        }
      });
    });

    return !isChecked;
  }

  /** 関数 */

  /** n段階評価設問選択時 */
  private onChangeMain(questionnaireSelectionId: number) {
    // チェック状態を全解除後、対象のチェック状態をON
    this.inquiryAnswer.questionnaire_answers.forEach(questionAnswer => {
      if (questionAnswer.question_type != 1) {
        return;
      }
      questionAnswer.selections.forEach(selectionAnswer => {
        selectionAnswer.is_checked =
          questionnaireSelectionId ==
          selectionAnswer.inquiry_questionnaire_selection_id
            ? 1
            : 0;
      });
    });
  }

  /** 回答するボタン押下時 */
  private async onClickAnswerButton() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    this.answerQuestionnaire();
  }

  /** Emit */

  /** CSアンケート回答 */
  @Emit("click:answer")
  private answerQuestionnaire() {
    return this.inquiryAnswer;
  }
}
