






































































































































































import {
  Component,
  Mixins,
  Emit,
  Prop,
  Watch,
  Ref
} from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import DateMixin from "#/mixins/dateMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { Choice, VForm } from "@/types";
import { DispDocInfo } from "@/components/patient/types";

// モデル
import {
  VisitSchdule,
  DefaultVsistSchedule,
  Rule,
  DefaultRule,
  DefaultVisitScheduleStaffs,
  VisitScheduleStaffs
} from "#/model/schedule";
import { OfficeStaffChoice } from "#/model/staff";
import { PatientSituation, DefaultPatientSituation } from "#/model/patient";

// common
import {
  RepeatDiv,
  EditRangeDiv,
  RepeatPeriodDiv,
  getEventWeekDays,
  getEventWeeks,
  getEventWeekTimes
} from "#/components/calendar/common";
import * as appDate from "#/utility/appDate";
import RulesMixin from "#/mixins/rulesMixin";
import * as constant from "#/const";
import ScheduleAssistant from "./visit_plan/ScheduleAssistant.vue";
import VisitPlanVisitor from "./visit_plan/VisitPlanVisitor.vue";
import VisitPlanRepeat from "./visit_plan/VisitPlanRepeat.vue";
import VisitPlanSituation from "./visit_plan/VisitPlanSituation.vue";

@Component({
  components: {
    ScheduleAssistant,
    VisitPlanVisitor,
    VisitPlanRepeat,
    VisitPlanSituation
  }
})
export default class VisitPlanEditEventDialog extends Mixins(
  UtilMixin,
  AxiosMixin,
  RulesMixin,
  DateMixin
) {
  /** 利用者一覧 */
  @Prop({ default: () => [] }) patients!: Choice[];

  /** 訪問者一覧 */
  @Prop({ default: () => [] }) staffs!: OfficeStaffChoice[];

  /** 利用者個別予定の編集を行うか */
  @Prop({ default: false }) editableIndividual!: boolean;

  /** 現在の日付 */
  @Prop({ default: "" }) focus!: string;

  /** 画面保存 */
  @Emit() private update() {
    return;
  }

  /** 入力フォーム */
  @Ref("form") private readonly form!: VForm;

  /** 日次カレンダーコンポーネント */
  @Ref("assistant") private readonly assistant!: ScheduleAssistant;

  /** 繰り返し設定変更 */
  @Watch("rule.repeat_state") private changeRepeatDiv() {
    // 繰り返し設定ありで期限開始日未設定の場合、編集日をセット
    if (
      this.rule.repeat_state != RepeatDiv.None &&
      this.rule.visit_limit_start_date == ""
    ) {
      this.rule.visit_limit_start_date = this.visitDate;
    }

    // 繰り返し設定ありで各設定値が未設定の場合初期値をセット
    const limitStart = appDate.strToDate(this.rule.visit_limit_start_date);
    switch (this.rule.repeat_state) {
      case RepeatDiv.EveryDay: // 毎日
        break;
      case RepeatDiv.EveryWeek: // 毎週
        this.rule.visit_week = getEventWeekDays(
          this.rule.visit_week,
          limitStart
        );
        break;
      case RepeatDiv.EveryMonthSpecifiedDate: // 毎月（日付指定）
        if (!this.rule.visit_day) {
          this.rule.visit_day = limitStart.getDate();
        }
        break;
      case RepeatDiv.EveryMonthSpecifiedWeek: // 毎月（週指定）
        this.rule.visit_week_weeks = getEventWeeks(
          this.rule.visit_week_weeks,
          limitStart
        );
        this.rule.visit_week = getEventWeekDays(
          this.rule.visit_week,
          limitStart
        );
        break;
      case RepeatDiv.EveryMonthSpecifiedWeekDay: // 毎月（曜日指定）
        this.rule.visit_week_times = getEventWeekTimes(
          this.rule.visit_week_times,
          limitStart
        );
        this.rule.visit_week = getEventWeekDays(
          this.rule.visit_week,
          limitStart
        );
        break;
    }
  }

  /** 利用者ID変更 */
  @Watch("visitSchedule.patient_id") private changePaitentId() {
    this.fetchPatientInfo();
    //patientSituationの利用者IDも変更する
    this.patientSituation.patient_id = this.visitSchedule.patient_id;
  }

  /**
   *  終日変更時
   */
  @Watch("rule.all_day_flg")
  private onChangeAllDay() {
    if (this.rule.all_day_flg !== 1) {
      return;
    }
    this.rule.shift_start_time_hour =
      String(this.rule.shift_start_time_hour) === ""
        ? 0
        : this.rule.shift_start_time_hour;
    this.rule.shift_start_time_minute =
      String(this.rule.shift_start_time_minute) === ""
        ? 0
        : this.rule.shift_start_time_minute;
    this.rule.shift_end_time_hour =
      String(this.rule.shift_end_time_hour) === ""
        ? 0
        : this.rule.shift_end_time_hour;
    this.rule.shift_end_time_minute =
      String(this.rule.shift_end_time_minute) === ""
        ? 0
        : this.rule.shift_end_time_minute;
  }

  /** ダイアログ展開状態 */
  private dialog = false;

  /** 訪問日 */
  private visitDate = "";

  /** 繰り返し区分 */
  private RepeatDiv = RepeatDiv;

  /** 繰り返し期限区分 */
  private RepeatPeriodDiv = RepeatPeriodDiv;

  /** 編集範囲区分 */
  private EditRangeDiv = EditRangeDiv;

  /** 訪問予定 */
  private visitSchedule: VisitSchdule = DefaultVsistSchedule();

  /** ルール */
  private rule: Rule = DefaultRule();

  /** ルール（バックアップ） */
  private bkRule: Rule = DefaultRule();

  /** 編集範囲選択値 */
  private selectedEditRange = EditRangeDiv.This;

  /** 書類情報 */
  private docInfos: DispDocInfo[] = [];

  /** 訪問可能職種リスト */
  private visitableDivs: string[] = [];

  /** 同行可能職種リスト */
  private accompanableDivs: string[] = [];

  /** 精神利用者かどうか */
  private isMental = false;

  /** 編集かどうか */
  private isEdit = false;

  /** コピーかどうか */
  private isCopy = false;

  /** 利用者状況 */
  private patientSituation: PatientSituation = DefaultPatientSituation();

  /** 利用者状況作成・編集かどうか */
  private isSituation = false;

  /** 訪問予定職員 */
  private get ScheduleStaffs(): VisitScheduleStaffs[] {
    return this.visitSchedule.visit_schedule_staffs;
  }

  /** 主訪問者 */
  private get MainStaff(): number {
    return this.getStaffId("main_staff_id", 0);
  }
  private set MainStaff(id: number) {
    this.setStaffId("main_staff_id", 0, id);
  }

  /** 副訪問者１ */
  private get SubStaff1(): number {
    return this.getStaffId("sub_staff1_id", 1);
  }
  private set SubStaff1(id: number) {
    this.setStaffId("sub_staff1_id", 1, id);
  }

  /** 副訪問者２ */
  private get SubStaff2(): number {
    return this.getStaffId("sub_staff2_id", 2);
  }
  private set SubStaff2(id: number) {
    this.setStaffId("sub_staff2_id", 2, id);
  }

  /** 書類情報 */
  private get docInfoText(): string {
    if (!this.docInfos) {
      return "";
    }
    const infos = this.docInfos.map(info => info.text);
    return infos.join("/");
  }

  /** ダイアログを開く */
  public open(
    eventId: number,
    editDate: string,
    defaultHH = 0,
    defaultMM = 0,
    patientId = 0,
    editRange = EditRangeDiv.New,
    staffId = 0,
    isCopy = false,
    situationId = 0
  ) {
    this.visitDate = editDate;
    this.selectedEditRange = editRange;
    this.visitSchedule = DefaultVsistSchedule();
    this.rule = DefaultRule();
    this.bkRule = DefaultRule();
    this.docInfos = [];
    this.accompanableDivs = [];
    this.visitableDivs = [];
    this.patientSituation = DefaultPatientSituation();
    this.isSituation = false;
    if (staffId) {
      this.MainStaff = staffId;
    }
    this.dialog = true;
    if (eventId == 0 && situationId == 0) {
      // 新規作成
      const minute = defaultMM - (defaultMM % 30);
      this.visitSchedule.visit_start_date = this.visitDate;
      this.visitSchedule.visit_end_date = this.visitDate;
      this.visitSchedule.patient_id = patientId;
      this.visitSchedule.shift_start_time_hour = defaultHH;
      this.visitSchedule.shift_start_time_minute = minute;
      const endDate = appDate.strToDate(editDate);
      endDate.setHours(defaultHH);
      endDate.setMinutes(minute + 60);
      this.visitSchedule.shift_end_time_hour = endDate.getHours();
      this.visitSchedule.shift_end_time_minute = endDate.getMinutes();
      this.rule = DefaultRule();
      this.bkRule = DefaultRule();
      this.initRule();

      this.patientSituation.target_date = this.visitDate;
      this.patientSituation.patient_id = patientId;
    } else {
      // 編集フラグ
      this.isEdit = true;
      // コピーフラグ
      this.isCopy = isCopy;
      // apiからイベントデータ取得
      if (situationId > 0) {
        // 利用者状況
        this.fetchPatientSituation(situationId);
      } else {
        // 訪問予定
        this.fetch(eventId);
      }
    }
  }

  /** ダイアログを閉じる */
  public close() {
    this.isEdit = false;
    this.dialog = false;
  }

  /** 訪問予定・ルール取得 */
  private fetch(eventId: number) {
    this.postJsonCheck(
      window.base_url + "/api/schedule/visitschedule/get",
      {
        visit_schedule_id: eventId
      },
      res => {
        if (res.data.visit_schedule) {
          this.visitSchedule = res.data.visit_schedule;
          if (!this.visitSchedule.visit_schedule_staffs) {
            this.visitSchedule.visit_schedule_staffs = DefaultVisitScheduleStaffs();
          }
        }
        if (res.data.rule) {
          this.rule = res.data.rule;
          this.bkRule = this.deepCopy(res.data.rule);
        }
        this.initRule();
        if (this.isCopy) {
          this.rule.id = 0;
          this.visitSchedule.id = 0;
        }
      }
    );
  }

  /** 利用者情報取得 */
  private fetchPatientInfo() {
    if (!this.visitSchedule.patient_id) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/schedule/patientinfo/get",
      {
        patient_id: this.visitSchedule.patient_id
      },
      res => {
        if (res.data) {
          this.docInfos = res.data.doc_infos || [];
          this.visitableDivs = res.data.visitable_divs || [];
          this.accompanableDivs = res.data.accompanable_divs || [];
          this.isMental = res.data.is_mental || false;
        }
      }
    );
  }

  /** ルールを初期化する */
  private initRule() {
    if (this.rule.id === 0) {
      this.rule.visit_limit_start_date = this.visitDate;
    } else {
      if (this.rule.repeat_state) {
        this.changeEditRange();
      }
    }
  }

  /** 編集範囲を変更する */
  private changeEditRange() {
    this.rule.repeat_state = this.bkRule.repeat_state;
    switch (this.selectedEditRange) {
      case EditRangeDiv.This:
        // この予定を選択した場合は繰り返しをなしに変更
        this.rule.repeat_state = RepeatDiv.None;
        break;
      case EditRangeDiv.After:
        this.rule.visit_limit_start_date = this.visitDate;
        break;
      case EditRangeDiv.All:
        this.rule.visit_limit_start_date = this.bkRule.visit_limit_start_date;
        break;
    }
  }

  /** 訪問予定を保存する */
  private saveVisitSchedule() {
    this.postJsonCheck(
      window.base_url + "/api/schedule/visitschedule/save",
      {
        visit_schedule: this.visitSchedule
      },
      async res => {
        const overlapSchedule = res.data.overlap_schedule_texts;
        await this.alertOverlapSchedule(overlapSchedule);
        this.update();
        this.close();
      }
    );
  }

  /** 訪問予定を削除する */
  private deleteVisitSchedule() {
    this.postJsonCheck(
      window.base_url + "/api/schedule/visitschedule/delete",
      {
        visit_schedule: this.visitSchedule,
        mode: 0
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  /** ルールを保存する */
  private saveRule() {
    this.rule.patient_id = this.visitSchedule.patient_id;
    this.postJsonCheck(
      window.base_url + "/api/schedule/rule/save",
      {
        edit_range: this.selectedEditRange,
        rule: this.rule,
        visit_schedule_id: this.visitSchedule.id,
        event_type: this.visitSchedule.event_type,
        event_title: this.visitSchedule.event_title,
        visit_memo: this.visitSchedule.visit_memo,
        memo_display: this.visitSchedule.memo_display,
        content: this.visitSchedule.content
      },
      async res => {
        const overlapSchedule = res.data.overlap_schedule_texts;
        await this.alertOverlapSchedule(overlapSchedule);
        this.update();
        this.close();
      }
    );
  }

  /** 重複した訪問予定がある場合はアラート */
  private async alertOverlapSchedule(schedules: string[]) {
    let text = "";
    if (schedules.length === 0) {
      return;
    } else if (schedules.length === 1) {
      text = "同じ時間帯に【";
      text += schedules[0];
      text += "】の予定が登録されているため、登録できません";
    } else {
      text = "同じ時間帯に複数の予定が登録されているため、登録できません\n\n";
      schedules.forEach(schedule => {
        text += schedule + "\n\n";
      });
    }

    await this.$openAlert(text);
  }

  /** ルールを削除する */
  private deleteRule() {
    this.postJsonCheck(
      window.base_url + "/api/schedule/rule/delete",
      {
        edit_range: this.selectedEditRange,
        rule: this.rule,
        mode: 0
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  /** 登録する */
  private async edit() {
    // 個別予定ではない場合、不要な項目は登録しない（表示時の分岐削減）
    if (!this.visitSchedule.event_type) {
      this.visitSchedule.event_title = "";
    }
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (this.visitSchedule.event_type && this.isSituation) {
      // 利用者状況を登録
      this.savePatientSituation();
      return;
    }

    // 精神利用者 かつ PT,STが主訪問者の場合は確認ダイアログ表示
    if (!(await this.isMentalVisitByPTST())) {
      return;
    }

    // ルール登録
    if (this.rule.repeat_state !== RepeatDiv.None) {
      this.saveRule();
      return;
    }
    if (
      this.selectedEditRange === EditRangeDiv.This ||
      this.selectedEditRange === EditRangeDiv.New
    ) {
      // 編集範囲が新規・この予定のみ
      this.saveVisitSchedule();
      return;
    }
  }

  /** 削除する */
  private async del() {
    if (this.visitSchedule.event_type && this.isSituation) {
      // 利用者状況の削除
      if (
        !(await this.$openConfirm("この登録情報を削除してもよろしいですか？"))
      ) {
        return;
      }
      this.deletePatientSituation();
      return;
    }

    if (
      this.selectedEditRange === EditRangeDiv.This ||
      this.selectedEditRange === EditRangeDiv.New
    ) {
      // 編集範囲が単独・この予定のみ
      this.deleteVisitSchedule();
    } else {
      this.deleteRule();
    }
  }

  /** 期限終了日のルール */
  private validateLimitEndDate(): boolean | string {
    if (this.rule.repeat_state === RepeatDiv.None) {
      return true;
    }
    if (this.rule.visit_limit !== RepeatPeriodDiv.Date) {
      return true;
    }
    if (this.rule.visit_limit_end_date === "") {
      return "必須です";
    }
    if (this.rule.visit_limit_end_date < this.rule.visit_limit_start_date) {
      return "期限日が不正です";
    }
    const s = appDate.strToDate(this.rule.visit_limit_start_date).getTime();
    const e = appDate.strToDate(this.rule.visit_limit_end_date).getTime();
    const diff = (e - s) / (24 * 60 * 60 * 1000);
    if (diff > 364) {
      return "終了日は1年以内で入力してください。";
    }

    return true;
  }

  /** 訪問時刻のルール */
  private validateVisitTime(): string | boolean {
    if (
      this.rule.repeat_state === RepeatDiv.None ||
      this.rule.all_day_flg === 1
    ) {
      return true;
    }
    if (
      this.rule.shift_start_time_hour > this.rule.shift_end_time_hour ||
      (this.rule.shift_start_time_hour === this.rule.shift_end_time_hour &&
        this.rule.shift_start_time_minute > this.rule.shift_end_time_minute)
    ) {
      return "終了時間は開始時間以降に設定してください";
    }
    return true;
  }

  /** 利用者カレンダーダイアログを開く */
  private openPatientDialog() {
    const name = "CalendarWebPrint";
    const target = this.visitSchedule.visit_start_date;
    const WebPrintRoute = this.$router.resolve({
      name: name,
      params: { id: String(this.visitSchedule.patient_id) },
      query: { target: target }
    });
    window.open(WebPrintRoute.href, "_blank");
  }

  // 精神利用者 かつ PT,STが主訪問者の場合は確認ダイアログ表示
  private async isMentalVisitByPTST(): Promise<boolean> {
    if (this.MainStaff) {
      // 主訪問者
      const selectMainStaff = this.staffs.filter(
        staff => staff.value === this.MainStaff
      );
      if (
        selectMainStaff.length != -1 &&
        (selectMainStaff[0].job_type === constant.OFFICE_STAFF.STAFF_DIV_PT ||
          selectMainStaff[0].job_type === constant.OFFICE_STAFF.STAFF_DIV_ST) &&
        this.isMental
      ) {
        // 利用者名取得
        const selectPatient = this.patients.filter(
          patient => patient.value === this.visitSchedule.patient_id
        );

        if (
          !(await this.$openConfirm(
            `利用者${selectPatient[0].text}さんは精神訪問ですが、\n精神訪問を行うことができないPTもしくはSTの職員が主訪問者に設定されています。\nこのまま登録してよろしいですか？`
          ))
        ) {
          return false;
        }
      }
    }
    return true;
  }

  /** 職員IDを取得する */
  private getStaffId(
    key: "main_staff_id" | "sub_staff1_id" | "sub_staff2_id",
    index: number
  ): number {
    if (this.rule.repeat_state !== RepeatDiv.None) {
      return this.rule[key] as number;
    }
    return this.visitSchedule.visit_schedule_staffs[index]?.staff_id ?? 0;
  }

  /** 職員IDを設定する */
  private setStaffId(
    key: "main_staff_id" | "sub_staff1_id" | "sub_staff2_id",
    index: number,
    id: number
  ) {
    if (this.rule.repeat_state !== RepeatDiv.None) {
      this.rule[key] = id;
    } else {
      if (this.ScheduleStaffs[index] == null) {
        // 職員が取得できなければ、デフォルトでID:0
        this.ScheduleStaffs[index] = DefaultVisitScheduleStaffs()[index];
      }
      this.ScheduleStaffs[index].staff_id = id;
    }
  }

  /** 利用者状況取得 */
  private fetchPatientSituation(situationId: number) {
    this.postJsonCheck(
      window.base_url + "/api/patient/situation/get",
      { situation_id: situationId },
      res => {
        if (res.data.patient_situation) {
          this.patientSituation = res.data.patient_situation;
          this.visitSchedule.patient_id = this.patientSituation.patient_id;
          this.visitSchedule.event_type = 1;
          this.isSituation = true;
        }
      }
    );
  }

  /** 利用者状況を保存する */
  private async savePatientSituation() {
    // 場所の施設コードリセット
    if (this.patientSituation.place != 2) {
      this.patientSituation.place_facility_div = 0;
    }
    this.postJsonCheck(
      window.base_url + "/api/patient/situation/save",
      {
        patient_situation: this.patientSituation
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  /** 利用者状況を削除する */
  private deletePatientSituation() {
    this.postJsonCheck(
      window.base_url + "/api/patient/situation/delete",
      {
        patient_situation: this.patientSituation
      },
      () => {
        this.update();
        this.close();
      }
    );
  }
}
