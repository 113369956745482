








































































































































































































































































































































































































































































































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { Choice, VForm } from "@/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import PatientMixin from "@/mixins/patientMixin";
import IndicateMixin from "@/mixins/indicateMixin";
import RulesMixin from "#/mixins/rulesMixin";
import EditMixin from "@/mixins/editMixin";
import { Smid, Smids } from "@/components/patient/indicate/types";
import {
  Indicate,
  DefaultIndicate,
  IndicateBeppyo7Disease,
  DefaultIndicateBeppyo7Disease,
  IndicateBeppyo8State,
  DefaultIndicateBeppyo8State,
} from "#/model/patient";
import { OfficeHistory } from "#/model/agreement";
import AppDisease from "@/components/disease/AppDisease.vue";
import AppDiseaseDialog from "@/components/disease/AppDiseaseDialog.vue";
import AppDiseaseChip from "@/components/disease/AppDiseaseChip.vue";
import DiseaseTable from "@/components/disease/DiseaseTable.vue";

import { DiseaseMeta, DiseaseWord } from "#/model/patient";
import PatientDoctorSelect, {
  SearchItem,
} from "@/components/patient/common_ibow/PatientDoctorSelect.vue";
import DateInput from "#/components/DateInput.vue";
import { namespace } from "vuex-class";
import * as ocr from "@/store/modules/ocr/types";
import { TargetKind } from "@/const/ocr";
import AiRecommendDiseaseButton from "@/components/common_ibow/AiRecommendDiseaseButton.vue";

const Ocr = namespace(ocr.name);

interface BeppyoDisease {
  id: number;
  cord: string;
  cord_after_r6: string;
  disease_name: string;
}

@Component({
  components: {
    PatientHeader,
    AppDisease,
    AppDiseaseDialog,
    AppDiseaseChip,
    PatientDoctorSelect,
    DateInput,
    AiRecommendDiseaseButton,
    DiseaseTable,
  },
})
export default class PatientIndicateEdit extends Mixins(
  UtilMixin,
  AxiosMixin,
  PatientMixin,
  IndicateMixin,
  RulesMixin,
  EditMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;
  @Ref("patient-doctor-select")
  private readonly patientDoctorSelect!: PatientDoctorSelect;
  @Ref("disease-dialog") private readonly diseaseDialog!: AppDiseaseDialog;

  @Ocr.Mutation(ocr.CLEAR_STATE) clearOcrState!: Function;
  @Ocr.Getter("OcrState") ocrState!: ocr.OcrState;

  private ranges = [
    {
      value: 0,
      text: "未選択",
    },
    {
      value: 1,
      text: "1ヶ月",
    },
    {
      value: 2,
      text: "2ヶ月",
    },
    {
      value: 3,
      text: "3ヶ月",
    },
    {
      value: 4,
      text: "4ヶ月",
    },
    {
      value: 5,
      text: "5ヶ月",
    },
    {
      value: 6,
      text: "6ヶ月",
    },
  ];
  private indicateId = 0;
  private patientId = 0;
  private mode = "";
  private isLoaded = false;
  private birthday = "";
  private beppyo7Dialog = false;
  private beppyo8Dialog = false;
  private smidDialog = false;
  private range = 0;

  private indicate: Indicate = DefaultIndicate(); //指示書
  private officeHistorys: OfficeHistory[] = []; //事業所情報履歴
  private medicalItems: Choice[] = []; //関連機関（医療機関）items
  private beppyo7Diseases: IndicateBeppyo7Disease[] = []; //別表7マスタ
  private beppyo8States: IndicateBeppyo8State[] = []; //別表8マスタ
  private beppyo7Selects: IndicateBeppyo7Disease[] = []; //選択された別表7
  private beppyo8Selects: IndicateBeppyo8State[] = []; //選択された別表8
  private medicalDiseases: DiseaseMeta[] = [];
  private smids: Smid[] = Smids(); //重症児判定
  private motorFunction = false; //運動機能：座位までチェック
  private smidSelects: Smid[] = []; //選択された重症時判定
  private careStation: Choice[] = []; //訪問看護ステーションマスタ
  private primaryDoctorId = 0; //かかりつけ医ID
  private primaryDoctorDeleteFlg = false; //かかりつけ医削除フラグ
  private mainDisease1 = "";
  private mainDisease2 = "";
  private mainDisease3 = "";
  private otherDisease = "";
  private firstMedicalDisease: DiseaseMeta[] = [];

  //今日の年月日を返す
  private today(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate());
    const today = date.toISOString().substr(0, 10);
    return today;
  }

  //開始日が今日よりも過去チェック
  private async pastCheck(): Promise<boolean> {
    let pastFlg = 0;
    if (this.indicate.start_date) {
      const today = new Date(this.today().replace(/-/g, "/"));
      const start = new Date(this.indicate.start_date.replace(/-/g, "/"));
      if (today > start) {
        pastFlg = 1;
      }
    }
    if (pastFlg) {
      return await this.$openConfirm(
        "開始日が過去の日付になっておりますが、よろしいですか？"
      );
    }
    return true;
  }

  // コロナ臨時を2024-04-01以降非表示
  private isShowCovit(): boolean {
    if (this.indicate.start_date) {
      const covit = new Date("2024-03-31");
      const start = new Date(this.indicate.start_date.replace(/-/g, "/"));
      return start <= covit;
    }
    return false;
  }

  private judgeDoctor(): boolean {
    let result = false;

    //開始、終了に今日を含む場合
    let effect = false;
    if (this.indicate.start_date && this.indicate.end_date) {
      const today = new Date(this.today().replace(/-/g, "/"));
      const start = new Date(this.indicate.start_date.replace(/-/g, "/"));
      const end = new Date(this.indicate.end_date.replace(/-/g, "/"));
      if (start <= today && today <= end) {
        effect = true;
      }
    }
    //基礎情報のかかりつけ医と医療機関情報の医師が被っている場合（開始、終了に今日を含む場合に限る）
    if (this.primaryDoctorId == this.indicate.doctor_id && effect) {
      result = true;
    }
    return result;
  }

  //使用する事業所情報判定と編集
  private get judgeOfficeHistory(): OfficeHistory {
    const judgeOfficeHistorys: OfficeHistory[] = [];
    let start: Date;
    let end: Date;
    //開始日、終了日がからの場合は今日の日付
    if (this.indicate.start_date == "" || this.indicate.end_date == "") {
      start = new Date(this.today().replace(/-/g, "/"));
      end = new Date(this.today().replace(/-/g, "/"));
    } else {
      start = new Date(this.indicate.start_date.replace(/-/g, "/"));
      end = new Date(this.indicate.end_date.replace(/-/g, "/"));
    }
    //指示書期間と事業所履歴期間が被っている事業所情報の抽出
    for (const officeHistory of this.officeHistorys) {
      const officeStart = new Date(officeHistory.start_date.replace(/-/g, "/"));
      let officeEnd: Date;
      //事業所終了日がからの場合9999-12-31にする
      if (officeHistory.end_date == "") {
        officeEnd = new Date("9999/12/31");
      } else {
        officeEnd = new Date(officeHistory.end_date.replace(/-/g, "/"));
      }
      if (
        (start <= officeStart && officeStart <= end) ||
        (officeStart <= start && end <= officeEnd) ||
        (start <= officeEnd && officeEnd <= end)
      ) {
        judgeOfficeHistorys.push(officeHistory);
      }
    }

    //指示書で使う事業所情報の編集（加算項目の表示非表示は、抽出した事業所情報の中に1回でも有効なものがあれば有効にする）
    const officeHistory = { ...judgeOfficeHistorys[0] };
    for (const judgeOfficeHistory of judgeOfficeHistorys) {
      //介護:特別管理加算
      if (judgeOfficeHistory.care_tokubetsu_kanri_add == 1) {
        officeHistory.care_tokubetsu_kanri_add = 1;
      }
      //医療:特別管理加算
      if (judgeOfficeHistory.medical_tokubetsu_kanri_add == 1) {
        officeHistory.medical_tokubetsu_kanri_add = 1;
      }
      //24時間対応体制加算
      if (judgeOfficeHistory.medical_hour24_taiou_add == 1) {
        officeHistory.medical_hour24_taiou_add = 1;
      }
      //24時間連絡体制加算
      if (judgeOfficeHistory.medical_hour24_renraku_add == 1) {
        officeHistory.medical_hour24_renraku_add = 1;
      }
      //介護:緊急時訪問
      if (judgeOfficeHistory.care_kinkyu_houmon_add == 1) {
        officeHistory.care_kinkyu_houmon_add = 1;
      } else if (judgeOfficeHistory.care_kinkyu_houmon_add == 2) {
        officeHistory.care_kinkyu_houmon_add = 2;
      }
      //精神科複数回訪問
      if (judgeOfficeHistory.medical_seishin_fukusukai_add == 1) {
        officeHistory.medical_seishin_fukusukai_add = 1;
      }
      //精神科訪問看護基本療養費（これは加算項目を出す、出さないとは訳が違うので、１つでもなしの事業所履歴がある場合はなしにする）
      if (judgeOfficeHistory.medical_is_seishin_kihon_ryouyouhi == 0) {
        officeHistory.medical_is_seishin_kihon_ryouyouhi = 0;
      }
    }
    return officeHistory;
  }

  //主たる傷病名横に入力主傷病名表示
  private get primaryDisease() {
    let str = "主たる傷病名";
    const disesas = [];
    const md = [...this.medicalDiseases];

    if (this.indicate.main_disease1) {
      disesas.push(this.indicate.main_disease1);
    } else if (md.length > 0) {
      disesas.push(md.shift()?.name);
    }
    if (this.indicate.main_disease2) {
      disesas.push(this.indicate.main_disease2);
    } else if (md.length > 0) {
      disesas.push(md.shift()?.name);
    }
    if (this.indicate.main_disease3) {
      disesas.push(this.indicate.main_disease3);
    } else if (md.length > 0) {
      disesas.push(md.shift()?.name);
    }
    if (disesas.length > 0) {
      str += "（";
      str += disesas.join(" ／ ");
      str += "）";
    }
    return str;
  }

  //別表7
  private beppyo7Headers: DataTableHeader[] = [
    {
      text: "コード",
      value: "beppyo7_disease_code",
      width: "30px",
      align: "center",
      class: "pa-1",
    },
    {
      text: "項目",
      value: "beppyo7_disease_name",
      width: "250px",
      align: "center",
      class: "pa-1",
    },
  ];
  //別表8
  private beppyo8Headers: DataTableHeader[] = [
    {
      text: "令和6年5月\r\nまでのコード",
      value: "beppyo8_state_code",
      width: "80px",
      align: "center",
      class: "pa-1",
    },
    {
      text: "令和6年6月\r\nからのコード",
      value: "beppyo8_state_code_after_r6",
      width: "80px",
      align: "center",
      class: "pa-1",
    },
    {
      text: "項目",
      value: "beppyo8_state_name",
      width: "200px",
      align: "center",
      class: "pa-1",
    },
  ];
  //主傷病名検索
  private diseaseHeaders: DataTableHeader[] = [
    {
      text: "傷病名",
      value: "name",
      width: "300px",
      align: "center",
      class: "pa-1",
    },
  ];
  //重症児判定
  private smidHeaders: DataTableHeader[] = [
    {
      text: "",
      value: "no",
      width: "5px",
      align: "center",
      class: "pa-1",
    },
    {
      text: "項目",
      value: "item",
      width: "250px",
      align: "center",
      class: "pa-1",
    },
    {
      text: "点数",
      value: "score",
      width: "30px",
      align: "center",
      class: "pa-1",
    },
  ];

  created() {
    this.indicateId = Number(this.$route.params.indicateid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchIndicate();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list",
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/indicate?key=Normal`,
      },
      {
        text: "普通指示書",
        disabled: true,
        to: "",
      },
    ]);
  }

  destroy() {
    this.clearOcrState();
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  /** 変換前傷病名が存在する */
  private remainsPrevDisease() {
    return (
      this.indicate.main_disease1 != "" ||
      this.indicate.main_disease2 != "" ||
      this.indicate.main_disease3 != "" ||
      this.indicate.other_disease != ""
    );
  }

  //登録
  private async save() {
    if (!(await this.selectBeppyo8Code41("update"))) {
      return;
    }
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    if (!(await this.pastCheck())) {
      return;
    }
    //事業所の精神科訪問看護基本療養費なしの場合
    if (
      !this.judgeOfficeHistory.medical_is_seishin_kihon_ryouyouhi &&
      this.indicate.indicate_div == 2
    ) {
      if (
        !(await this.$openConfirm(
          "精神科訪問看護基本療養費が取得できない設定になっています。このまま精神科訪問看護指示を登録した場合、訪問看護指示と同様の取り扱いとなりますが宜しいですか"
        ))
      ) {
        return;
      }
    }
    //主傷病名・その他傷病名の入力がない場合
    if (
      this.indicate.main_disease1 === "" &&
      this.indicate.main_disease2 === "" &&
      this.indicate.main_disease3 === "" &&
      this.indicate.other_disease === "" &&
      this.medicalDiseases.length == 0
    ) {
      if (
        !(await this.$openConfirm(
          "傷病名が入力されていません。傷病名は医療保険でレセプトを行うときに1つ以上必要です。このまま提出してもよろしいですか？"
        ))
      ) {
        return;
      }
    }
    if (this.mode) {
      this.indicate.id = 0;
    }

    if (this.remainsPrevDisease()) {
      if (
        !(await this.$openConfirm(
          "[変換前傷病名]が残っています。このまま登録しますか？\n[変換前傷病名]は医療保険請求分のオンライン請求には連携されません。"
        ))
      ) {
        return;
      }
    }

    //別表8の選択内容と特別管理Ⅰ/ⅡのON状態を合わせるか聞く
    await this.changeSpecialAddFromBeppyo8();

    //点滴注射指示が有効でない場合は注射指示期間を空にする
    if (!this.indicate.is_injection) {
      this.indicate.injection_start_date = "";
      this.indicate.injection_end_date = "";
    }

    //24時間連絡体制加算の場合hour24_divを2で保存
    if (
      this.indicate.hour24_div &&
      this.judgeOfficeHistory.medical_hour24_renraku_add
    ) {
      this.indicate.hour24_div = 2;
    }
    this.indicate.patient_id = this.patientId;

    // 保存直前、別表8状態が選択されていなければ、対応する加算項目もOFFにする
    if (this.indicate.states.length == 0) {
      this.indicate.longtime_beppyo_child_div = 0;
      this.indicate.info_expense2_beppyo_child_div = 0;
    }

    this.primaryDoctorDeleteFlg = false;

    // 主治医とかかりつけ医の重複チェック
    this.postJsonCheck(
      window.base_url + "/api/patient/indicate/doctor/check",
      {
        indicate: this.indicate,
      },
      async (res) => {
        if (res.data.check_message) {
          if (
            await this.$openConfirm(
              "選択された医師は適用期間が以下の利用者履歴でかかりつけ医として登録されています。かかりつけ医の登録を解除し、主治医として登録しますか？\n\n" +
                res.data.check_message
            )
          ) {
            this.primaryDoctorDeleteFlg = true;
          }
        }
        this.postJsonCheck(
          window.base_url + "/api/patient/indicate/save",
          {
            indicate: this.indicate,
            primary_doctor_delete_flg: this.primaryDoctorDeleteFlg,
            medical_diseases: this.medicalDiseases,
          },
          async (res) => {
            if (res.data.code > 0) {
              await this.$openAlert(res.data.message);
              return;
            }

            // OCR 結果から作成成功した場合は OCR ステータスを「作成済み」にする
            const from = this.$route.query.from;
            if (from === "ocr") {
              this.applyOcr();
            }

            this.setNoEditMode();
            this.cancel();
          }
        );
      }
    );
  }

  private applyOcr() {
    if (this.ocrState.documentType === 0 || this.ocrState.documentId === 0) {
      return;
    }
    this.postJsonBackground(
      window.base_url + "/api/ocrstore/mapping/apply",
      {
        document_type: this.ocrState.documentType,
        document_id: this.ocrState.documentId,
      },
      () => {
        // do nothing
      }
    );
  }

  //変更
  private async update() {
    this.indicate.id = this.indicateId;
    this.save();
  }

  //削除
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.indicate.id = this.indicateId;
      this.indicate.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/indicate/delete",
        {
          indicate: this.indicate,
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  //重症児判定
  private async judgment() {
    let total = 0;
    let judgmentResult = "【対象外】";
    this.indicate.longtime_severe_child_div = 0;
    this.indicate.smid_flg = 0;
    this.indicate.semi_smid_flg = 0;
    for (const smidSelect of this.smidSelects) {
      total += smidSelect.score;
    }
    if (this.motorFunction) {
      if (total >= 25) {
        judgmentResult = "【超重症児】";
        this.indicate.longtime_severe_child_div = 1;
        this.indicate.smid_flg = 1;
        this.indicate.semi_smid_flg = 0;
      } else if (total >= 10) {
        judgmentResult = "【準超重症児】";
        this.indicate.longtime_severe_child_div = 1;
        this.indicate.smid_flg = 0;
        this.indicate.semi_smid_flg = 1;
      }
    }
    await this.$openAlert(
      "判定結果は" + judgmentResult + "です\n" + "点数：" + total
    );
    this.smidDialog = false;
  }

  //普通指示書情報取得
  private fetchIndicate(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/indicate/get",
      {
        patient_id: this.patientId,
        indicate_id: this.indicateId,
      },
      (res) => {
        this.officeHistorys = res.data.office_historys;
        this.birthday = res.data.birthday;
        this.medicalItems = res.data.medical_items;
        this.beppyo7Diseases = res.data.beppyo_7_disease.map(
          (diseaseMaster: BeppyoDisease) => ({
            ...DefaultIndicateBeppyo7Disease(),
            beppyo7_disease_id: diseaseMaster.id,
            beppyo7_disease_code: diseaseMaster.cord,
            beppyo7_disease_name: diseaseMaster.disease_name,
          })
        );
        this.beppyo8States = res.data.beppyo_8_state.map(
          (stateMaster: BeppyoDisease) => ({
            ...DefaultIndicateBeppyo8State(),
            beppyo8_state_id: stateMaster.id,
            beppyo8_state_code_after_r6:
              stateMaster.cord == "41" ? "廃止" : stateMaster.cord_after_r6,
            beppyo8_state_name: stateMaster.disease_name,
            beppyo8_state_code: stateMaster.cord ? stateMaster.cord : "-",
          })
        );
        this.careStation = res.data.care_station;
        this.primaryDoctorId = res.data.primary_doctor_id;
        this.medicalDiseases = res.data.medical_diseases;
        this.firstMedicalDisease = [...res.data.medical_diseases];
        //普通指示書情報
        if (this.indicateId != 0) {
          this.indicate = res.data.indicate;
          this.mainDisease1 = this.indicate.main_disease1;
          this.mainDisease2 = this.indicate.main_disease2;
          this.mainDisease3 = this.indicate.main_disease3;
          this.otherDisease = this.indicate.other_disease;
          if (this.mode) {
            this.indicate.start_date = "";
            this.indicate.end_date = "";
            this.indicate.injection_start_date = "";
            this.indicate.injection_end_date = "";
          }
          //24時間加算制御
          //24時間対応体制加算（医療）だがdivが2の場合
          if (
            this.judgeOfficeHistory.medical_hour24_taiou_add &&
            this.indicate.hour24_div == 2
          ) {
            this.indicate.hour24_div = 0;
          }
          //24時間連絡体制加算（医療）だがdivが1の場合
          if (
            this.judgeOfficeHistory.medical_hour24_renraku_add &&
            this.indicate.hour24_div == 1
          ) {
            this.indicate.hour24_div = 0;
          }
          //別表7制御
          if (!this.indicate.diseases) {
            this.indicate.diseases = [];
          }
          //別表8制御
          if (!this.indicate.states) {
            this.indicate.states = [];
          }
          //編集時に別表8ダイアログを開かなくても、特別管理ON/OFFを切り替えるか尋ねる
          this.beppyo8Selects = this.deepCopy(this.indicate.states);
        }

        // OCR 結果から作成の場合、初期値設定
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.setOcrParams();
        }

        this.$nextTick(() => {
          this.isLoaded = true;
        });
        this.setLoaded();
      }
    );
  }

  private setOcrParams() {
    // 指示書
    if (this.ocrState.targetKind === TargetKind.indicateNormal) {
      this.indicate.indicate_div = 1; //普通指示書
      this.indicate.start_date = this.ocrState.indicate.startDate;
      this.indicate.end_date = this.ocrState.indicate.endDate;
      this.indicate.injection_start_date = this.ocrState.indicate.injectionStartDate;
      this.indicate.injection_end_date = this.ocrState.indicate.injectionEndDate;
      if (
        this.indicate.injection_start_date !== "" ||
        this.indicate.injection_end_date !== ""
      ) {
        this.indicate.is_injection = 1;
      }
      this.indicate.main_disease1 = this.ocrState.indicate.mainDisease1;
      this.indicate.main_disease2 = this.ocrState.indicate.mainDisease2;
      this.indicate.main_disease3 = this.ocrState.indicate.mainDisease3;
      this.indicate.other_disease = this.ocrState.indicate.otherDisease;
      this.indicate.doctor_indicate = this.ocrState.indicate.doctorIndicate;
    }
    // 精神指示書
    if (this.ocrState.targetKind === TargetKind.indicateMental) {
      this.indicate.indicate_div = 2; //精神指示書
      this.indicate.start_date = this.ocrState.indicate.startDate;
      this.indicate.end_date = this.ocrState.indicate.endDate;
      this.indicate.main_disease1 = this.ocrState.indicate.mainDisease1;
      this.indicate.main_disease2 = this.ocrState.indicate.mainDisease2;
      this.indicate.main_disease3 = this.ocrState.indicate.mainDisease3;
      this.indicate.other_disease = this.ocrState.indicate.otherDisease;
      this.indicate.doctor_indicate = this.ocrState.indicate.doctorIndicate;
    }

    if (
      this.ocrState.targetKind === TargetKind.indicateNormal ||
      this.ocrState.targetKind === TargetKind.indicateMental
    ) {
      const hospitalName = this.ocrState.indicate.hospitalName;
      const doctorName = this.ocrState.indicate.doctorName;
      if (hospitalName !== "" || doctorName !== "") {
        const cond: SearchItem = {
          med_name: hospitalName,
          tel_area: "",
          name: doctorName,
        };
        this.patientDoctorSelect.openDialogWithCondition(cond);
      }
    }
  }

  // 終了期間
  private handleRange() {
    if (this.range == 0 || this.indicate.start_date == "") {
      return;
    }

    const date = new Date(this.indicate.start_date.replace(/-/g, "/"));
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);

    switch (this.range) {
      case 1:
        date.setMonth(date.getMonth() + 1);
        date.setDate(date.getDate() - 1);
        this.indicate.end_date = date.toISOString().substr(0, 10);
        break;
      case 2:
        date.setMonth(date.getMonth() + 2);
        date.setDate(date.getDate() - 1);
        this.indicate.end_date = date.toISOString().substr(0, 10);
        break;
      case 3:
        date.setMonth(date.getMonth() + 3);
        date.setDate(date.getDate() - 1);
        this.indicate.end_date = date.toISOString().substr(0, 10);
        break;
      case 4:
        date.setMonth(date.getMonth() + 4);
        date.setDate(date.getDate() - 1);
        this.indicate.end_date = date.toISOString().substr(0, 10);
        break;
      case 5:
        date.setMonth(date.getMonth() + 5);
        date.setDate(date.getDate() - 1);
        this.indicate.end_date = date.toISOString().substr(0, 10);
        break;
      case 6:
        date.setMonth(date.getMonth() + 6);
        date.setDate(date.getDate() - 1);
        this.indicate.end_date = date.toISOString().substr(0, 10);
        break;
    }
  }

  //-----------------------------------------------------
  // ダイアログ
  //-----------------------------------------------------
  //別表7ダイアログを開く
  private beppyo7Open() {
    this.beppyo7Selects = this.deepCopy(this.indicate.diseases);

    // 09のコードを持っているか
    if (this.beppyo7Selects.some((d) => d.beppyo7_disease_code === "09")) {
      // 現在の09コードの選択肢を削除して、新しく追加する
      this.beppyo7Selects = this.beppyo7Selects.filter((d) => d.beppyo7_disease_code !== "09");
      const beppyo7DiseaseCode09 = this.beppyo7Diseases.find((d) => d.beppyo7_disease_code === "09");
        if (beppyo7DiseaseCode09) {
          this.beppyo7Selects.push({
          ...DefaultIndicateBeppyo7Disease(),
          indicate_id: this.indicate.id,
          beppyo7_disease_id: beppyo7DiseaseCode09?.beppyo7_disease_id || 0,
          beppyo7_disease_code: beppyo7DiseaseCode09?.beppyo7_disease_code || "",
          beppyo7_disease_name: beppyo7DiseaseCode09?.beppyo7_disease_name || "",
        });
      }
    }

    // 10のコードを持っているか
    if (this.beppyo7Selects.some((d) => d.beppyo7_disease_code === "10")) {
      // 10のコードのうち、いずれかが選択されている場合は、それを選択済みにする
      const beppyo7DiseaseCode10 = this.beppyo7Diseases.find((d) => d.beppyo7_disease_code === "10");
      if (beppyo7DiseaseCode10) {
        this.beppyo7Selects.push({
          ...DefaultIndicateBeppyo7Disease(),
          indicate_id: this.indicate.id,
          beppyo7_disease_id: beppyo7DiseaseCode10?.beppyo7_disease_id || 0,
          beppyo7_disease_code: beppyo7DiseaseCode10?.beppyo7_disease_code || "",
          beppyo7_disease_name: beppyo7DiseaseCode10?.beppyo7_disease_name || "",
        });
      }
    }
    this.beppyo7Dialog = true;
  }
  //別表8ダイアログを開く
  private beppyo8Open() {
    this.beppyo8Selects = this.deepCopy(this.indicate.states);
    this.beppyo8Dialog = true;
  }

  //別表8ダイアログを閉じる
  private async closeBeppyo8Dialog() {
    if (!(await this.selectBeppyo8Code41("cancel"))) {
      return;
    }
    this.beppyo8Dialog = false;
  }
  //重症児判定ダイアログを開く
  private smidOpen() {
    this.smidDialog = true;
  }

  //別表7の選択を保存データに適用する
  private applySelectedBeppyo7Dialog() {
    this.beppyo7Dialog = false;

    const selectIds = this.beppyo7Selects.map((b) => b.beppyo7_disease_id);
    const diseaseIds = this.indicate.diseases.map((b) => b.beppyo7_disease_id);
    if (
      JSON.stringify(selectIds.sort()) !== JSON.stringify(diseaseIds.sort())
    ) {
      //選択を変更したので、選択済み別表7リストのidを0にして新規保存扱いとする
      const indicateBeppyo7 :IndicateBeppyo7Disease[] = [];
      const addedCodes: string[] = [];

      for (const select of this.beppyo7Selects) {
        if ((select.beppyo7_disease_code == "09" && addedCodes.includes("09")) || (select.beppyo7_disease_code == "10" && addedCodes.includes("10"))) {
          continue;
        }

        indicateBeppyo7.push({
          ...DefaultIndicateBeppyo7Disease(),
          indicate_id: this.indicate.id,
          beppyo7_disease_id: select.beppyo7_disease_id,
          beppyo7_disease_code: select.beppyo7_disease_code,
          beppyo7_disease_name: select.beppyo7_disease_name,
        });

        addedCodes.push(select.beppyo7_disease_code);
      }
      this.indicate.diseases = indicateBeppyo7;
    }
  }

  //別表8の選択を保存データに適用する
  private async applySelectedBeppyo8Dialog() {
    //別表8の選択内容と特別管理Ⅰ/ⅡのON状態を合わせるか聞く
    await this.changeSpecialAddFromBeppyo8();

    // 在宅悪性腫瘍患者指導管理を選択した時に注意を促す
    if (!(await this.selectBeppyo8Code41("confirm"))) {
      return;
    }
    this.beppyo8Dialog = false;

    const selectIds = this.beppyo8Selects.map((b) => b.beppyo8_state_id);
    const stateIds = this.indicate.states.map((b) => b.beppyo8_state_id);
    if (JSON.stringify(selectIds.sort()) !== JSON.stringify(stateIds.sort())) {
      //選択を変更した場合、選択済み別表8リストidを0にして新規保存扱いとする
      this.indicate.states = this.beppyo8Selects.map((select) => ({
        ...DefaultIndicateBeppyo8State(),
        indicate_id: this.indicate.id,
        beppyo8_state_id: select.beppyo8_state_id,
        beppyo8_state_code: select.beppyo8_state_code,
        beppyo8_state_code_after_r6: select.beppyo8_state_code_after_r6,
        beppyo8_state_name: select.beppyo8_state_name,
      }));
    }
  }

  //-----------------------------------------------------
  // Watch表示切り替え
  //-----------------------------------------------------
  //編集モード設定
  @Watch("indicate", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
  //編集モード設定
  @Watch("medicalDiseases", { deep: true })
  private watchDiseaseData() {
    if (this.IsLoaded && this.changedDisease()) {
      this.setEditMode();
    }
  }

  //種類
  @Watch("indicate.indicate_div")
  private handleIndicateDiv() {
    if (!this.isLoaded) {
      return;
    }
    //普通指示書の場合認知症は0
    if (this.indicate.indicate_div == 1) {
      this.indicate.is_dementia = 0;
    }
    //精神科以外の場合は医療観察訪問看護フラグは0
    if (this.indicate.indicate_div != 2) {
      this.indicate.is_mtsa = 0;
    }
  }

  //別表7表示
  private get dispBeppyo7Selects() {
    const contents: string[] = [];
    const addedCodes: string[] = [];
    if (this.indicate.diseases.length > 0) {
      contents.push("【 選択済別表7状態 】");
    }
    for (const select of this.indicate.diseases) {
      if ((select.beppyo7_disease_code == "09" && addedCodes.includes("09")) || (select.beppyo7_disease_code == "10" && addedCodes.includes("10"))) {
        continue;
      }
      let name = select.beppyo7_disease_name;
      
      if (select.beppyo7_disease_code == "09") {
        name = " パーキンソン病関連疾患（進行性核上性麻痺、大脳皮質基底核変性症及びパーキンソン病（ホーエン・ヤールの重症度分類がステージ３以上であって生活機能障害度がⅡ度又はⅢ度のものに限る。））"
      } else if (select.beppyo7_disease_code == "10") {
        name = "多系統萎縮症（線条体黒質変性症、オリーブ橋小脳萎縮症及びシャイ・ドレーガー症候群）"
      }
      contents.push(
        `${select.beppyo7_disease_code}:${name}`
      );
      addedCodes.push(select.beppyo7_disease_code);
    }
    return contents;
  }

  //別表8表示
  private get dispBeppyo8Selects() {
    const contents: string[] = [];
    if (this.indicate.states.length > 0) {
      contents.push("【 選択済別表8状態 】");
    }
    for (const select of this.indicate.states) {
      if (select.beppyo8_state_code == "41") {
        contents.push(
          `${select.beppyo8_state_code}:${select.beppyo8_state_name}` +
            "　※令和6年5月31日に廃止されるコードです。"
        );
      } else {
        contents.push(
          `${select.beppyo8_state_code_after_r6}:${select.beppyo8_state_name}`
        );
      }
    }
    return contents;
  }

  //特別管理1
  @Watch("indicate.special_add1")
  private handleSpecialAdd1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.indicate.special_add1 == 1) {
      this.indicate.special_add2 = 0;
    }
  }
  //特別管理2
  @Watch("indicate.special_add2")
  private handleSpecialAdd2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.indicate.special_add2 == 1) {
      this.indicate.special_add1 = 0;
    }
  }

  //超重症児
  @Watch("indicate.smid_flg")
  private handleSmid() {
    if (!this.isLoaded) {
      return;
    }
    if (this.indicate.smid_flg == 1) {
      this.indicate.semi_smid_flg = 0;
    }
  }

  //準超重症児
  @Watch("indicate.semi_smid_flg")
  private handleSemiSmid() {
    if (!this.isLoaded) {
      return;
    }
    if (this.indicate.semi_smid_flg == 1) {
      this.indicate.smid_flg = 0;
    }
  }

  private changedDisease() {
    return (
      JSON.stringify(this.firstMedicalDisease) !==
      JSON.stringify(this.medicalDiseases)
    );
  }

  //-----------------------------------------------------
  // 必須入力切り替え
  //-----------------------------------------------------
  //主傷病名1：他と病名が被っている場合
  private checkMainDisease1(): boolean | string {
    if (!this.indicate.main_disease1) {
      return true;
    }
    if (
      this.indicate.main_disease1 !=
      (this.indicate.main_disease2 || this.indicate.main_disease3)
    ) {
      return true;
    } else {
      return "主傷病名に同じものが記入されています";
    }
  }
  //主傷病名2：他と病名が被っている場合
  private checkMainDisease2(): boolean | string {
    if (!this.indicate.main_disease2) {
      return true;
    }
    if (
      this.indicate.main_disease2 !=
      (this.indicate.main_disease1 || this.indicate.main_disease3)
    ) {
      return true;
    } else {
      return "主傷病名に同じものが記入されています";
    }
  }
  //主傷病名3：他と病名が被っている場合
  private checkMainDisease3(): boolean | string {
    if (!this.indicate.main_disease3) {
      return true;
    }
    if (
      this.indicate.main_disease3 !=
      (this.indicate.main_disease1 || this.indicate.main_disease2)
    ) {
      return true;
    } else {
      return "主傷病名に同じものが記入されています";
    }
  }

  /**
   * 変換前傷病名表示条件
   */
  private existMainDisease() {
    return (
      (this.mainDisease1 != "" &&
        this.indicate.is_main_disease1_converted == 0) ||
      (this.mainDisease2 != "" &&
        this.indicate.is_main_disease2_converted == 0) ||
      (this.mainDisease3 != "" &&
        this.indicate.is_main_disease3_converted == 0) ||
      this.otherDisease != ""
    );
  }

  //他の訪問看護ステーションへの指示：被っている場合
  private checkOtherRelationInstitution(): boolean | string {
    if (!this.indicate.other1_relation_institution_id) {
      return true;
    }
    if (
      this.indicate.other1_relation_institution_id !=
      this.indicate.other2_relation_institution_id
    ) {
      return true;
    } else {
      return "同じ訪問看護ステーションは選択できません";
    }
  }

  //長時間加算ありの場合、指示書期間（開始日）に15歳未満の年齢となる日付が含まれているかチェック
  private checkUnder15YearsOld(longTimeDiv: number): boolean | string {
    // そもそもチェックが入っていない場合は年齢チェックをしない
    if (longTimeDiv == 0) {
      return true;
    }

    // 開始日が入っていない場合もチェックをしない
    if (this.indicate.start_date == "") {
      return true;
    }

    const age = this.calcAge(this.indicate.start_date, this.birthday);
    if (age >= 15) {
      return "指示書期間に15歳未満の年齢となる日付が含まれていないため長時間訪問看護加算は選択できません";
    }
    return true;
  }

  //18歳未満の小児で別表8に掲げる者への訪問看護もしくは18歳未満の超重症児または準超重症児への訪問看護ありの場合、指示書期間（開始日）に18歳未満の年齢となる日付が含まれているかチェック
  private checkUnder18YearsOld(infoExpenseDiv: number): boolean | string {
    // そもそもチェックが入っていない場合は年齢チェックをしない
    if (infoExpenseDiv == 0) {
      return true;
    }
    // 開始日が入っていない場合もチェックをしない
    if (this.indicate.start_date == "") {
      return true;
    }

    const age = this.calcAge(this.indicate.start_date, this.birthday);
    if (age >= 18) {
      return "指示書期間に18歳未満の年齢となる日付が含まれていないため、18歳未満の小児で別表8に掲げる者への訪問看護もしくは18歳未満の超重症児または準超重症児への訪問看護は選択できません";
    }
    return true;
  }

  /** 指示書開始日と誕生日から年齢を計算 */
  private calcAge(startDate: string, birthDay: string): number {
    const rStart = startDate.replaceAll("-", "");
    const rBirthday = birthDay.replaceAll("-", "");
    const age = Math.floor((Number(rStart) - Number(rBirthday)) / 10000);

    return age;
  }

  /** 別表8の選択内容によって特別管理Ⅰ/ⅡをONにするか調べる */
  private async changeSpecialAddFromBeppyo8() {
    //コード41~44のいずれかが選択されていれば、特別管理ⅠをONにする
    //コード45~57のいずれかが選択されていれば、特別管理ⅡをONにする
    //ただし両方ONの場合、そうはできないので特別管理ⅠだけをONにする
    let add1 = false;
    let add2 = false;
    this.beppyo8Selects.forEach((select) => {
      if (
        Number(select.beppyo8_state_code_after_r6) <= 46 ||
        Number(select.beppyo8_state_code) == 41
      ) {
        add1 = true;
      } else if (Number(select.beppyo8_state_code_after_r6) >= 47) {
        add2 = true;
      }
    });
    if (add1 && add2) {
      add2 = false;
    }
    if (
      this.indicate.special_add1 !== Number(add1) ||
      this.indicate.special_add2 !== Number(add2)
    ) {
      const msg =
        "別表8の登録内容に合わせて、特別管理加算の設定を変更しますか？";
      if (await this.$openConfirm(msg)) {
        this.indicate.special_add1 = Number(add1);
        this.indicate.special_add2 = Number(add2);
      }
    }
  }

  /** 在宅悪性腫瘍患者指導管理を選択したときに注意を促す */
  private async selectBeppyo8Code41(dialog: string) {
    let msg = "";

    console.log("old: ", this.indicate.states);
    console.log("new: ", this.beppyo8Selects.forEach);

    switch (dialog) {
      case "cancel": {
        // 元々旧41がONで、かつONのまま閉じようとした場合
        let isOn41Old = false;
        for (const select of this.indicate.states) {
          if (Number(select.beppyo8_state_code) == 41) {
            isOn41Old = true;
            break;
          }
        }

        if (isOn41Old) {
          this.beppyo8Selects.forEach((select) => {
            if (Number(select.beppyo8_state_code) == 41) {
              msg =
                "令和6年6月から廃止になるコード（41 在宅悪性腫瘍患者指導管理）にチェックが入っています。変更せずに閉じますか？";
            }
          });
        }
        break;
      }

      case "confirm": {
        this.beppyo8Selects.forEach((select) => {
          if (Number(select.beppyo8_state_code) == 41) {
            msg =
              "令和6年6月から廃止になるコード（41 在宅悪性腫瘍患者指導管理）にチェックが入っています。このまま登録しますか？";
          }
        });
        break;
      }
      case "update": {
        this.beppyo8Selects.forEach((select) => {
          if (Number(select.beppyo8_state_code) == 41) {
            msg =
              "現在の状態＞[別表8状態]で令和6年6月から廃止になるコード（41 在宅悪性腫瘍患者指導管理）にチェックが入っています。このまま登録しますか？";
          }
        });
        break;
      }
      default:
        return true;
    }

    if (msg.length === 0) {
      return true;
    } else {
      return await this.$openConfirm(msg);
    }
  }

  /** 既存主傷病名をコード化（変換時） */
  private convertDisease(id: string) {
    let name = "";
    switch (id) {
      case "main-disease1":
        name = this.indicate.main_disease1;
        break;
      case "main-disease2":
        name = this.indicate.main_disease2;
        break;
      case "main-disease3":
        name = this.indicate.main_disease3;
        break;
      default:
        return;
    }
    this.diseaseDialog.open(this.loginUser.office_id, id, 2, name);
  }

  /** 主傷病名を追加（傷病名コード化） */
  private addDisease() {
    this.diseaseDialog.open(this.loginUser.office_id);
  }

  /** 主傷病名を編集（傷病名コード化） */
  private editDisease(meta: DiseaseMeta) {
    const words: DiseaseWord[] = [];
    meta.words.forEach((word) => {
      words.push(word);
    });
    this.diseaseDialog.open(this.loginUser.office_id, {
      id: meta.id,
      name: meta.name,
      name_kana: meta.name_kana,
      words: words,
      selected: false,
      created_at: meta.created_at,
      updated_at: meta.updated_at,
      deleted_at: meta.deleted_at,
    });
  }

  /** 主傷病名を削除（傷病名コード化） */
  private async deleteDisease(idx: number) {
    const d = this.medicalDiseases[idx];
    if (!(await this.$openConfirm(`"${d.name}"を削除しますか？`))) {
      return;
    }
    this.medicalDiseases.splice(idx, 1);
  }

  /** 主傷病名の登録処理（傷病名コード化） */
  private async registerDisease(meta: DiseaseMeta) {
    if (typeof meta.id === "string") {
      this.setConverted(meta.id);
      meta.id = 0;
    }

    if (meta.id === 0) {
      //追加
      //idは一意の値にする。新規追加の場合は、常にマイナス最小値を設定
      let minVal = 0;
      if (this.medicalDiseases.length > 0) {
        minVal = Math.min(...this.medicalDiseases.map((p) => p.id as number));
      }
      meta.id = minVal - 1;
      this.medicalDiseases.push(meta);
    } else {
      //編集
      let idx = -1;
      this.medicalDiseases.forEach((d, i) => {
        if (d.id == meta.id) {
          idx = i;
        }
      });
      if (idx !== -1) {
        this.medicalDiseases.splice(idx, 1, meta);
      }
    }
  }

  /** 主傷病名の変換済み情報のセット（傷病名コード化） */
  private setConverted(id: string) {
    switch (id) {
      case "main-disease1":
        this.indicate.is_main_disease1_converted = 1;
        this.indicate.main_disease1 = "";
        break;
      case "main-disease2":
        this.indicate.is_main_disease2_converted = 1;
        this.indicate.main_disease2 = "";
        break;
      case "main-disease3":
        this.indicate.is_main_disease3_converted = 1;
        this.indicate.main_disease3 = "";
        break;
    }
  }
}
