












































































import { Component, Mixins } from "vue-property-decorator";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";

interface SearchCond {
  kind: number;
  office_id: number;
  year_month: string;
  target_date: string;
  is_all_history: boolean;
  is_char_limit: boolean;
  is_blank_output: boolean;
}

interface Kind {
  value: number;
  text: string;
  is_yearmonth?: boolean;
  is_all?: boolean;
  is_date?: boolean;
}

@Component({
  components: {
    OfficeSelect
  }
})
export default class SukoyakasanLink extends Mixins(AxiosMixin, UtilMixin) {
  // 現在日時
  private now = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  ).toISOString();

  // 検索条件
  private searchCond: SearchCond = {
    kind: 1001,
    office_id: 0,
    year_month: this.now.substr(0, 7),
    target_date: this.now.substr(0, 10),
    is_all_history: false,
    is_char_limit: false,
    is_blank_output: false
  };

  private kinds: Kind[] = [
    { value: 1001, text: "利用者情報" },
    { value: 1011, text: "被保険者証情報" },
    { value: 1012, text: "被保険者証支援事業所情報" },
    {
      value: 1019,
      text: "訪問看護療養主保険者証情報",
      is_yearmonth: true,
      is_all: true
    },
    { value: 1014, text: "公費情報", is_date: true, is_all: true },
    { value: 1016, text: "介護券情報", is_date: true },
    { value: 1015, text: "減免情報", is_date: true, is_all: true },
    { value: 2001, text: "スケジュール情報（実績）", is_yearmonth: true },
    { value: 4001, text: "訪問看護療養費情報", is_yearmonth: true },
    { value: 1020, text: "給付開始中止情報", is_date: true, is_all: true },
    { value: 4002, text: "公費本人支払額情報", is_date: true }
  ];

  private selectKind = this.kinds[0];

  created() {
    if (this.loginUser.office_id) {
      this.searchCond.office_id = this.loginUser.office_id;
    } else {
      this.searchCond.office_id = this.HeadOffice;
    }
  }

  /**
   * CSV出力ボタン押下時
   */
  private async clickOutputCsv() {
    let yearMonth; //ファイル名用月
    if (this.selectKind.is_yearmonth) {
      yearMonth =
        this.searchCond.year_month.substr(2, 2) +
        this.searchCond.year_month.substr(5, 2);
    } else if (this.selectKind.is_date) {
      yearMonth =
        this.searchCond.target_date.substr(2, 2) +
        this.searchCond.target_date.substr(5, 2);
    } else {
      yearMonth = this.dateToStr(new Date(), "yyyyMM").slice(2);
    }
    const fileName = `S${this.searchCond.kind + yearMonth}.csv`;

    // 事業所が未選択の場合、職員が所属する事業所を対象にする
    this.searchCond.office_id =
      this.searchCond.office_id == 0
        ? this.loginUser.office_id
        : this.searchCond.office_id;

    this.postJsonBlobResCheck(
      window.base_url + "/api/master/dataio/sukoyaka/output",
      this.searchCond,
      res => {
        if (res.data.size) {
          console.log(res);
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = fileName;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }

  /**
   * 日付の表示可否
   */
  private changeKind() {
    this.selectKind = this.kinds.find(
      kind => kind.value === this.searchCond.kind
    ) as Kind;

    this.setSukoyakaSelfBearDefaultCheck();
  }

  /**
   * 事業所情報取得
   */
  private setSukoyakaSelfBearDefaultCheck() {
    if (this.selectKind.value != 1014 && this.selectKind.value != 4002) return;
    // 事業所未選択の場合、職員の所属する事業所で取得する
    this.postJsonCheck(
      window.base_url + "/api/master/station/get_by_id",
      {
        office_id:
          this.searchCond.office_id == 0
            ? this.loginUser.office_id
            : this.searchCond.office_id
      },
      res => {
        this.searchCond.is_blank_output =
          res.data.office.office_info.sukoyaka_csv_self_bear_default_check == 1;
      }
    );
  }
}
