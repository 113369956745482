




































































































































































import { Component, Emit, Mixins, Prop, Ref } from "vue-property-decorator";
import { Inquiry, InquiryFile } from "#/model/inquiry";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm, Choice } from "@/types";
import { HOPE_CONTACT, HOPE_CONTACT_IBOW } from "@/const/contact";
import { SupportableDateSetting } from "#/model/admin/setting";
import InquiryFileUploader from "@/components/contact/InquiryFileUploader.vue";

@Component({
  components: {
    OfficeSelect,
    TextDayChangePicker,
    InquiryFileUploader
  }
})
export default class ContactFormNew extends Mixins(
  UtilMixin,
  AxiosMixin,
  RulesMixin
) {
  /** 問合せ情報 */
  @Prop() inquiry!: Inquiry;

  @Ref("contact_form") private readonly form!: VForm;

  private readonly SUPPORT_DAY_COUNT = 5; // 何営業日先まで選べるか
  private readonly SUPPORT_HOUR_START = 9; // 受付開始時
  private readonly SUPPORT_HOUR_END = 17; // 受付終了時
  private readonly SUPPORT_END_BEFORE_MINUTE = 20; // 受付終了時はn分前までなら選べる

  /** 問い合わせ種類Choice */
  private contactDivChoices: Choice[] = [
    { text: "iBowの操作", value: "iBowの操作" },
    { text: "レセプト", value: "レセプト" },
    { text: "訪問看護の制度", value: "訪問看護の制度" },
    { text: "iBow KINTAI", value: "iBow KINTAI" }
  ];

  /** アクセス承諾選択肢 */
  private accessConfirmItems = [
    {
      label: "確認した上での連絡を希望する",
      value: 2,
      disabled: false,
      isNotShow: false
    },
    {
      label: "確認せずに連絡してほしい",
      value: 1,
      disabled: false,
      isNotShow: false
    }
  ];

  /** 希望連絡方法Choice */
  private HOPE_CONTACT = HOPE_CONTACT;

  /** 希望連絡方法:iBow */
  private HOPE_CONTACT_IBOW = HOPE_CONTACT_IBOW;

  /** 希望連絡日時Choice */
  private hopeContactTimeChoices: Choice[] = [
    { text: "未選択", value: "" },
    { text: "09:00", value: "09:00:00" },
    { text: "10:00", value: "10:00:00" },
    { text: "11:00", value: "11:00:00" },
    { text: "12:00", value: "12:00:00" },
    { text: "13:00", value: "13:00:00" },
    { text: "14:00", value: "14:00:00" },
    { text: "15:00", value: "15:00:00" },
    { text: "16:00", value: "16:00:00" },
    { text: "17:00", value: "17:00:00" }
  ];

  /** 選択した問合せ種類 */
  private contactDivs: boolean[] = [];

  /** 希望連絡日 */
  private hopeContactDate = "";

  /** 希望連絡日時 */
  private hopeContactTime = this.hopeContactTimeChoices[0].value + "";

  /** 希望連絡日時 */
  private get hopeContactDateTime(): string {
    return `${this.hopeContactDate} ${this.hopeContactTime}`;
  }

  /** --------- 対応可能日関連 --------- */

  /** 祝日リスト(yyyy-MM-dd) */
  private pubHolidays: string[] = [];

  /** 土日であるか */
  private isHoliday(dateStr: string) {
    const date = this.newDate(dateStr);
    // 土日判定
    return date.getDay() === 6 || date.getDay() === 0;
  }
  /** 祝日であるか */
  private isPubHoliday(dateStr: string) {
    return !!this.pubHolidays.find(pubHolidayStr => dateStr === pubHolidayStr);
  }

  /** 管理画面上で対応可（時間選択不可）に設定されているか */
  private isTimeUnSelectableDate(dateStr: string) {
    return this.supporttables.some(
      supportSetting =>
        supportSetting.supportable === 2 && supportSetting.date === dateStr
    );
  }

  private supporttables: SupportableDateSetting[] = [];

  /** 設定されたサポート対応可能日 */
  private get supporttableOKDates(): string[] {
    // 0:対応可もしくは2:対応可（時間選択不可）の場合のみ
    return this.supporttables
      .filter(support => support.supportable !== 1)
      .map(support => support.date);
  }

  /** 設定されたサポート対応不可日 */
  private get supporttableNGDates(): string[] {
    return this.supporttables
      .filter(support => support.supportable === 1)
      .map(support => support.date);
  }

  /** 選択肢中の最終対応可能日時(例 17:00:00) */
  private get contactableLastTime(): string {
    const times = this.hopeContactTimeChoices
      .map(time => time.value + "")
      .sort();
    return times[times.length - 1];
  }

  /** 祝日またはカスタム対応不可日(yyyy-MM-dd) */
  private get unsupportDates() {
    const unsupportDates: string[] = [];
    this.pubHolidays.forEach(holidayDate => {
      // カスタムのサポート対応可能日でない祝日を追加
      if (!this.supporttableOKDates.includes(holidayDate)) {
        unsupportDates.push(holidayDate);
      }
    });
    // カスタムのサポート対応不可日を追加
    unsupportDates.push(...this.supporttableNGDates);

    return unsupportDates.sort(); // 早い順に並び替える
  }

  /* 当日以降の対応可能日 */
  private get contactableDates() {
    const dateList: string[] = [];
    /** 対応可能日かの判断対象 */
    const dateIncrement = new Date();

    /** 当日の最終対応可能日時 */
    const contactableLastDatetime = this.newDate(
      this.nowDtStr.slice(0, 10) + " " + this.contactableLastTime
    );
    // は選択肢の20分前
    contactableLastDatetime.setMinutes(-this.SUPPORT_END_BEFORE_MINUTE);
    // 当日だがもう対応可能時間を過ぎていれば、翌日から調べる
    if (new Date() >= contactableLastDatetime) {
      dateIncrement.setDate(dateIncrement.getDate() + 1);
    }

    // 無限ループを確実に防ぐ
    const loopMinCount = 20;
    let forceLoopLimit = Math.ceil(this.SUPPORT_DAY_COUNT * 2 + loopMinCount);
    // I日ずつ増やし、対応可能日が5営業日分貯まるまでループ
    while (forceLoopLimit > 0) {
      forceLoopLimit--;
      const dateStr = this.dateToStr(dateIncrement, "yyyy-MM-dd");
      // 土日でなければ原則対応可能日とする
      if (!this.isHoliday(dateStr)) {
        // 祝日またはカスタム対応不可日でなければ対応可能日とする
        if (!this.unsupportDates.includes(dateStr)) {
          dateList.push(dateStr);
        }
      } else if (this.supporttableOKDates.includes(dateStr)) {
        // 土日でもカスタム対応可能日なら対応可能日とする
        dateList.push(dateStr);
      }
      dateIncrement.setDate(dateIncrement.getDate() + 1);
    }

    return dateList.slice(0, this.SUPPORT_DAY_COUNT);
  }

  /** 対応可能日であるか */
  private isContactableDate(dateStr: string): boolean {
    return this.contactableDates.includes(dateStr);
  }

  /** 今日が対応可能日であるか */
  private get isContactableToday(): boolean {
    return this.contactableDates.includes(this.nowDtStr.slice(0, 10));
  }

  /** 明日以降の対応可能日を表示 */
  private setNextContactableDate() {
    const openDate = new Date(); // 営業日かもしれない日
    // 1日ずつ増やし、対応可能日ならそれを設定
    // 無限ループを防ぐため、とりあえず1か月先までで無ければ打ち切り
    for (let challenge = 0; challenge < 30; challenge++) {
      openDate.setDate(openDate.getDate() + 1); // 明日以降なので先頭で
      const openDateStr = this.dateToStr(openDate, "yyyy-MM-dd");
      if (this.isContactableDate(openDateStr)) {
        // 対応可能日が見つかった
        this.hopeContactDate = openDateStr;
        break;
      }
    }
  }

  /** 希望日時の入力に応じて実際の対応時間帯を表示 */
  private get contactTimeMsg(): string {
    const hopeDatetime = this.newDate(this.hopeContactDateTime);
    // 無効な日時指定の場合空文字を返す
    if (this.hopeContactTime === "" || Number.isNaN(hopeDatetime.getTime())) {
      return "";
    }
    // 基本的には選択した希望時間の前後1時間が開始予定/終了予定
    let hourStart = hopeDatetime.getHours() - 1;
    let hourEnd = hopeDatetime.getHours() + 1;
    let minuteStart = String(hopeDatetime.getMinutes()).padStart(2, "0");
    let minuteEnd = minuteStart;
    // ただし希望開始時間は受付開始前にしない
    if (hourStart < this.SUPPORT_HOUR_START) {
      hourStart = this.SUPPORT_HOUR_START;
      minuteStart = "00";
    }
    // 希望終了時間は受付終了後にしない
    if (hourEnd >= this.SUPPORT_HOUR_END) {
      hourEnd = this.SUPPORT_HOUR_END;
      minuteEnd = "00";
    }
    return `${hourStart}:${minuteStart}~${hourEnd}:${minuteEnd}を目安にご連絡いたします。`;
  }

  /** 今日を表す日付文字列 */
  private nowDtStr = this.dateToStr(new Date(), "yyyy-MM-dd HH:mm:ss");

  /** 事業所選択セレクトボックスを表示するか */
  private get officeSelectable() {
    // 所属事業所情報が存在しない場合に限る
    return !this.loginUser.office_id;
  }

  /** 希望時間の選択を無効にするか */
  private get disableContactTime() {
    const dateStr = this.hopeContactDate;
    // 土日祝なら無効
    // 平日でも、対応可（時間選択不可）の場合は無効
    return (
      this.isHoliday(dateStr) ||
      this.isPubHoliday(dateStr) ||
      this.isTimeUnSelectableDate(dateStr)
    );
  }

  public created() {
    this.fetchCalendar();
  }

  /** 祝日情報とサポート対応可否設定を取得 */
  private fetchCalendar() {
    this.postJsonCheck(
      window.base_url + "/api/inquiry/calendar/get",
      {},
      res => {
        if (!res.data) return;
        this.supporttables = res.data.supportable_date_settings;
        this.pubHolidays = res.data.holidays;
      }
    );
  }

  private async onSubmit(): Promise<void> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    if (!(await this.$openConfirm("この内容で送信しますか？"))) {
      return;
    }

    /** 送信用にコピーした問合せ情報 */
    const sendInquiry = this.deepCopy(this.inquiry);

    // 空の添付ファイル枠を削除 + sort_no振り直し
    sendInquiry.inquiry_files = sendInquiry.inquiry_files.flatMap(
      (inquiryFile: InquiryFile, index: number) => {
        const file = { ...inquiryFile };
        file.id = 0;
        file.sort_no = index + 1;
        file.file_path = file.path;
        return file.file_path == "" ? [] : file;
      }
    );

    /** 問合せ本文も加工 */
    sendInquiry.contact = this.formatInquiryBody(sendInquiry.contact);

    // 希望連絡日時を格納
    if (this.disableContactTime) {
      // 日付選択が無効なら、管理画面との兼ね合いで0時0分にして送る
      sendInquiry.hope_contact_datetime = `${this.hopeContactDate} 00:00:00`;
    } else {
      sendInquiry.hope_contact_datetime = this.hopeContactDateTime;
    }

    this.submit(sendInquiry);
  }

  /** お問合せ内容に種類を付け足すよう加工する */
  private formatInquiryBody(contact: string) {
    /** チェックされた種類の文字列を取得 */
    const selectedDivText = this.contactDivs
      .map((checked, idx) => {
        if (!checked) return "";
        return this.contactDivChoices[idx]?.text ?? "";
      })
      .filter(Boolean);
    /** 箇条書き形式に変換 */
    const listDivs = selectedDivText.map(text => "・" + text).join("\n");

    let tmpContact = "";
    if (listDivs !== "") {
      tmpContact = `[問合せ種類選択内容]
${listDivs}


`;
    }
    tmpContact += `[問合せ内容]
${contact}`;
    return tmpContact;
  }

  /** 希望連絡方法が変更された時 */
  private onChangeHopeContactDiv() {
    this.form.resetValidation();
  }

  /** 送信をキャンセル */
  @Emit()
  private cancel() {
    return;
  }

  /** 送信 */
  @Emit()
  private submit(inquiry: Inquiry) {
    return inquiry;
  }

  /** 希望連絡方法がどちらでも・電話の場合に電話番号が入力されていない場合はNG */
  private requireTelNo(): boolean | string {
    if (this.inquiry.hope_contact_div !== HOPE_CONTACT_IBOW) {
      if (this.inquiry.tel_no === "") {
        return "必須です";
      } else if (this.inquiry.tel_no.match(/[^\d-]/)) {
        return "電話番号は数字とハイフン(-)のみ入力できます";
      }
    }
    return true;
  }

  /** 希望連絡日に関するバリデーションチェック */
  private checkHopeContactDate(): boolean | string {
    // 希望連絡方法がどちらでもor電話の場合は希望連絡日は必須
    if (
      (this.inquiry.hope_contact_div === 1 ||
        this.inquiry.hope_contact_div === 2) &&
      this.hopeContactDate === ""
    ) {
      return "希望連絡日を選択してください。";
    }

    // 希望連絡方法がiBowシステムの場合は空を許可
    if (this.inquiry.hope_contact_div === 4 && this.hopeContactDate === "") {
      return true;
    }

    // 時間が未選択(00:00)の場合は日付のみを見て過去かどうかチェック
    if (this.hopeContactTime === "00:00:00" || this.hopeContactTime === "") {
      const hopeDateTime = `${this.hopeContactDate} 23:59:59`;
      if (this.newDate(hopeDateTime) < this.newDate(this.nowDtStr)) {
        return "未来の日付を選択してください。";
      }
    }
    return true;
  }

  /** 希望連絡時間に関するバリデーションチェック */
  private checkHopeContactTime(): boolean | string {
    // サポート時間を選べない土日祝ではチェックしない
    if (this.disableContactTime) {
      return true;
    }

    // 希望連絡方法がどちらでもor電話の場合は希望連絡日は必須
    if (
      (this.inquiry.hope_contact_div === 1 ||
        this.inquiry.hope_contact_div === 2) &&
      this.hopeContactTime === ""
    ) {
      return "時間帯を選択してください";
    }

    // 希望連絡方法がiBowシステムの場合はチェックしない
    if (this.inquiry.hope_contact_div === 4) {
      return true;
    }

    // 時間が選択されている場合は時間まで見て過去かどうかチェック
    const hopeDateTime = `${this.hopeContactDate} ${this.hopeContactTime}`;
    if (this.newDate(hopeDateTime) < this.newDate(this.nowDtStr)) {
      return "未来の時間を選択してください。";
    }
    return true;
  }
}
