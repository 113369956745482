var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-0",staticStyle:{"height":"100vh","position":"relative"}},[_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('AppTitle',{staticClass:"pre-check-result-title-area",attrs:{"label":"レセプト事前チェック"}}),_c('AppButton',{staticClass:"pre-check-result-title-area close-button",class:_vm.Color,style:({ backgroundColor: _vm.BgColor }),attrs:{"outlined":""},on:{"click":_vm.closeWindow}},[_vm._v("画面を閉じる")])],1),_c('AppTabs',{attrs:{"showArrows":false,"centerActive":false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('AppTab',[_vm._v("同一建物")]),_c('AppToolTip',{staticClass:"hint",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppIcon',_vm._g(_vm._b({staticClass:"ml-2 my-auto",attrs:{"icon":"help","fill":_vm.$vuetify.theme.themes.light.greyLight}},'AppIcon',attrs,false),on))]}}])},[_c('span',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(_vm.hint))])])],1),_c('div',{staticClass:"d-flex flex-row justify-end align-center"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.processState == 2),expression:"processState == 2"}],staticClass:"message"},[_c('span',{staticClass:"large-count"},[_vm._v(_vm._s(_vm.count)+"/"+_vm._s(_vm.total))]),_vm._v(" "+_vm._s("件の処理をしました")+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.processState == 3 || _vm.processState == 4 || _vm.processState == 6),expression:"processState == 3 || processState == 4 || processState == 6"}],staticClass:"message"},[_c('span',{staticClass:"large-count"},[_vm._v(_vm._s(_vm.count)+"/"+_vm._s(_vm.total))]),_vm._v(" "+_vm._s("件の処理をしました。下部の処理結果をご確認ください。")+" ")]),_c('AppButton',{staticClass:"mr-2 px-2",attrs:{"color":"error","disabled":_vm.InterruptButtonDisabled},on:{"click":function($event){return _vm.interruption()}}},[_vm._v("処理中断 ")]),_c('AppButton',{staticClass:"mr-2 px-2",attrs:{"color":"primary","disabled":_vm.StartButtonDisabled},on:{"click":function($event){return _vm.startProcess()}}},[_vm._v("処理開始")])],1),(_vm.tab == 0)?_c('div',{staticStyle:{"height":"50vh"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('p',{staticClass:"mb-0 grey--text",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])]):_vm._e(),_c('AppDataTable',{staticClass:"mt-2 pt-4 fixed-bottom",attrs:{"headers":_vm.header,"items":_vm.histories,"noDataText":"一覧が存在しません","isNoFilterButton":"","height":285,"fixed-header":"","fixed-footer":"","isNoTop":"false","isNoFooter":false},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('AppButton',{staticClass:"mr-1 mb-1 pa-1",attrs:{"color":"primary","height":300,"disabled":_vm.isInvalidLink(item.excel_path)},on:{"click":function($event){return _vm.downloadAll(item)}}},[_vm._v(" 結果ダウンロード ")])]}},{key:"item.status_flag",fn:function(ref){
var item = ref.item;
return [_c('AppChip',{attrs:{"color":_vm.getColorByStatus(item.status_flag)}},[_vm._v(_vm._s(_vm.statusText(item.status_flag)))])]}},{key:"foot",fn:function(){return [_c('InfiniteLoading',{ref:"infiniteLoading",attrs:{"identifier":_vm.resetInfinite,"spinner":"spiral"},on:{"infinite":_vm.getPreCheckHistory}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"})])]},proxy:true},{key:"footer",fn:function(){return [_c('v-divider'),_c('div'),_c('v-divider')]},proxy:true}])}),_c('AppMiniDialog',{attrs:{"cancelLabel":"画面を閉じる","saveLabel":"OK"},on:{"save":function($event){_vm.isOpenDialog = false},"cancel":function($event){return _vm.closeWindow()}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div')]},proxy:true}]),model:{value:(_vm.isOpenDialog),callback:function ($$v) {_vm.isOpenDialog=$$v},expression:"isOpenDialog"}},[[_c('span',[_vm._v(" "+_vm._s(_vm.selectedCount + "件の利用者を選択されています。" + _vm.targetYearmonth + "月は医療保険の確定状況一覧には")+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.totalCount)+" ")]),_c('span',[_vm._v(" "+_vm._s("件の利用者がいますが、よろしいですか？画面を閉じる/OK")+" ")])]],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"my-4"},[_vm._v(" このチェック機能を使用すると、医療保険における同一建物居住者に係る算定を自動で変更処理します。"),_c('br'),_c('br')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"attention"},[_vm._v("＜ご注意ください＞"),_c('br')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" ・対象利用者の基本療養費、訪問人数が変更されます。"),_c('br'),_vm._v(" ・処理後、実績確定状態が未確定状態へ変更されます。"),_c('br'),_vm._v(" ・画面を再度読み込みすると対象がリセットされます。"),_c('br')])}]

export { render, staticRenderFns }