
























































































import { Component, Emit, Mixins, Prop, Ref } from "vue-property-decorator";
import ReceiptEditAddMedV1 from "./ReceiptEditAddMedV1.vue";
import ReceiptEditAddMedV2 from "./ReceiptEditAddMedV2.vue";
import ReceiptEditAddMedV3 from "./ReceiptEditAddMedV3.vue";
import ReceiptEditAddMedV4 from "./ReceiptEditAddMedV4.vue";
import ReceiptEditAddMedV5 from "./ReceiptEditAddMedV5.vue";
import ReceiptEditAddMedV6 from "./ReceiptEditAddMedV6.vue";
import ReceiptEditAddMedV5Covit from "./ReceiptEditAddMedV5Covit.vue";
import ReceiptEditAddCareV1 from "./ReceiptEditAddCareV1.vue";
import ReceiptEditAddCareV2 from "./ReceiptEditAddCareV2.vue";
import ReceiptEditAddCareV3 from "./ReceiptEditAddCareV3.vue";
import ReceiptEditAddCareV4 from "./ReceiptEditAddCareV4.vue";
import ReceiptEditAddRegular from "./ReceiptEditAddRegular.vue";
import ReceiptEditAddRegularV2 from "./ReceiptEditAddRegularV2.vue";
import { CalendarEvent, VForm } from "@/types";
import { DISPLAY_RECEIPT_REGIST, DISPLAY_SERVICE_CONTENT } from "./types";
import {
  ReceiptVisitRecord,
  DefaultReceiptVisitRecord,
  ExceptForRegularTourDay,
  DefaultExceptForRegularTourDays,
  ResetMessage,
  RegistrationMessage,
  ResultsConfirmedMessage
} from "#/model/receipt";
import { OfficeHistory, DefaultOfficeHistory } from "#/model/agreement";
import AxiosMixin from "@/mixins/axiosMixin";
import ReceiptEditAddBase from "@/components/patient/receipt/ReceiptEditAddBase";
import { EventType } from "#/model/schedule/calendarType";

@Component({
  components: {
    ReceiptEditAddMedV1,
    ReceiptEditAddMedV2,
    ReceiptEditAddMedV3,
    ReceiptEditAddMedV4,
    ReceiptEditAddMedV5,
    ReceiptEditAddMedV5Covit,
    ReceiptEditAddMedV6,
    ReceiptEditAddCareV1,
    ReceiptEditAddCareV2,
    ReceiptEditAddCareV3,
    ReceiptEditAddCareV4,
    ReceiptEditAddRegular,
    ReceiptEditAddRegularV2
  }
})
export default class ReceiptDetailSlideMenu extends Mixins(AxiosMixin) {
  /** レセプト登録画面 */
  private DISPLAY_RECEIPT_REGIST = DISPLAY_RECEIPT_REGIST;

  /** サービス内容画面 */
  private DISPLAY_SERVICE_CONTENT = DISPLAY_SERVICE_CONTENT;

  /** 登録コンポーネント */
  @Ref("registPanelArea") private readonly registPanelArea!: ReceiptEditAddBase;

  /** 操作モード 0:レセプト登録 1:サービス内容 */
  @Prop({ default: "" }) display_option!: number;

  /** イベント */
  @Prop({ default: () => [] }) events!: CalendarEvent[];

  /** 訪問情報 */
  @Prop({ default: () => ({}) }) event!: CalendarEvent;

  /** 対象月 */
  @Prop({ default: "" }) yearmonth!: string;

  /** 利用者ID */
  @Prop({ default: 0 }) patientId!: number;

  /** 訪問記録（月内全て） */
  @Prop({ default: () => [] }) receiptVisitRecords!: ReceiptVisitRecord[];

  /** 入力フォーム */
  @Prop({ default: () => ({}) }) patientHeader!: VForm;

  /** 請求履歴確定済フラグ */
  @Prop({ default: 0 }) isBilledMedical!: number;
  @Prop({ default: 0 }) isBilledCare!: number;

  /** ほのぼの使用フラグ */
  @Prop({ default: 0 }) isHonobono!: number;

  /** ほのぼの事業所名 */
  @Prop({ default: "" }) honobonoJigyoKnj!: string;

  /** メッセージ項目 */
  @Prop({ default: () => ({}) }) messageItem!: {
    /** 登録時メッセージ */
    registrationMessages: RegistrationMessage[];
    /** 確定時メッセージ */
    resultsConfirmedMessage: ResultsConfirmedMessage;
    /** リセット時メッセージ */
    resetMessage: ResetMessage;
    /** 実績確定解除時メッセージ */
    releaseConfirmedResultsMessage: string;
    /** 登録メッセージ */
    registrationMessage: string;
  };

  /** 次の実績があるか */
  @Prop({ default: false }) hasNext!: boolean;

  @Emit() onClose() {
    return;
  }

  @Emit() submit() {
    return;
  }
  
  @Emit() update() {
    return;
  }

  @Emit() showNext() {
    return;
  }

  //自費での登録を通知
  @Emit() regiown(insuranceDiv: number) {
    return insuranceDiv;
  }

  /** 訪問情報編集パネル開閉状態 */
  private isOpenVisitEditPanel = false;

  /** 訪問情報編集パネルを常に開くか */
  private isOpenPanelLock = false;

  /** 訪問記録 */
  private receiptVisitRecord: ReceiptVisitRecord = DefaultReceiptVisitRecord(); //訪問記録

  /** 事業所情報 */
  private officeHistory: OfficeHistory = DefaultOfficeHistory(); //事業所情報

  /** 月末の日 */
  private endDay = "";

  /** 定期巡回除外日 */
  private exceptForRegularTourDays: ExceptForRegularTourDay[] = [
    DefaultExceptForRegularTourDays()
  ];

  /** 登録対象が在の時、同じ週に介護訪問があるかどうかのフラグ */
  private homeCancerCareFlg = false;

  /** HomeCancerCareFlgがtrueの場合の介護訪問がある日にち */
  private homeCancerCareDay = "";

  /** 12月超減算チェックボックス有効フラグ */
  private preventGensanDivFlg = false;

  /** 情報提供先に市町村等が含まれるか */
  private hasInfoProvider1 = false;

  /** 情報提供先に学校等が含まれるか */
  private hasInfoProvider2 = false;

  /** 情報提供先に保険医療機関等が含まれるか */
  private hasInfoProvider3 = false;

  /** 情報提供先に口腔連携強化加算が含まれるか */
  private hasInfoProviderMouthRenkei = false;

  /** イベントタイトル */
  private get EventTitle() {
    let eventTitle = "";

    if (!this.event) {
      return eventTitle;
    }

    switch (this.event.type) {
      case EventType.Record2NursingCare:
        eventTitle = "訪問看護（介護）";
        if (this.event.visit_record.care_div == 2) {
          eventTitle = "訪問看護（予防介護）";
        }
        break;
      case EventType.Record2Medical:
        eventTitle = "訪問看護（医療）";
        if (this.event.visit_record.mental_div == 2) {
          eventTitle = "精神訪問看護（医療）";
        }
        break;
      case EventType.Record2PassCrawl:
        eventTitle = "訪問看護（介護:定期巡回）";
        break;
    }

    return eventTitle;
  }

  /** 訪問詳細編集コンポーネント */
  private get VisitEditComponent() {
    if (this.event.type == EventType.Record2NursingCare) {
      if (this.yearmonth >= "2024-06") {
        return "ReceiptEditAddCareV4";
      }
      if (this.yearmonth >= "2021-04") {
        return "ReceiptEditAddCareV3";
      }
      if (this.yearmonth >= "2018-04") {
        return "ReceiptEditAddCareV2";
      }
      return "ReceiptEditAddCareV1";
    }
    if (this.event.type == EventType.Record2Medical) {
      if (this.yearmonth >= "2024-06") {
        return "ReceiptEditAddMedV6";
      }
      if (this.yearmonth >= "2024-04") {
        return "ReceiptEditAddMedV5Covit";
      }
      if (this.yearmonth >= "2023-10") {
        return "ReceiptEditAddMedV5";
      }
      if (this.yearmonth >= "2022-04") {
        return "ReceiptEditAddMedV4";
      }
      if (this.yearmonth >= "2020-04") {
        return "ReceiptEditAddMedV3";
      }
      if (this.yearmonth >= "2018-04") {
        return "ReceiptEditAddMedV2";
      }
      return "ReceiptEditAddMedV1";
    }
    if (this.event.type == EventType.Record2PassCrawl) {
      if (this.yearmonth >= "2024-06") {
        return "ReceiptEditAddRegularV2";
      }
      return "ReceiptEditAddRegular";
    }
    return "";
  }

  /** 画面幅は狭いか */
  private get IsNarrowView() {
    return this.$vuetify.breakpoint.width < 900;
  }

  /** データ取得 */
  public set(data: {
    receipt_visit_record: ReceiptVisitRecord;
    office_history: OfficeHistory;
    end_day: string;
    except_or_regular_tour_days: ExceptForRegularTourDay[];
    home_cancer_care_flg: boolean;
    home_cancer_care_day: string;
    prevent_gensan_div_flg: boolean;
    has_info_provider_1: boolean;
    has_info_provider_2: boolean;
    has_info_provider_3: boolean;
    has_info_provider_mouth_renkei: boolean;
  }) {
    this.receiptVisitRecord = data.receipt_visit_record;
    this.officeHistory = data.office_history;
    this.endDay = data.end_day;
    this.exceptForRegularTourDays = data.except_or_regular_tour_days;
    this.homeCancerCareFlg = data.home_cancer_care_flg;
    this.homeCancerCareDay = data.home_cancer_care_day;
    this.preventGensanDivFlg = data.prevent_gensan_div_flg;
    this.hasInfoProvider1 = data.has_info_provider_1;
    this.hasInfoProvider2 = data.has_info_provider_2;
    this.hasInfoProvider3 = data.has_info_provider_3;
    this.hasInfoProviderMouthRenkei = data.has_info_provider_mouth_renkei;
    this.isOpenVisitEditPanel = true;
  }

  /** 実績登録エリアを開く */
  public open() {
    this.isOpenVisitEditPanel = true;
  }

  /** 実績登録エリアを閉じる */
  public close() {
    this.isOpenVisitEditPanel = false;
    this.onClose();
  }

  /** 実績リセット */
  private async onClickReset() {
    this.submit();
    this.isOpenPanelLock = true;
    await this.registPanelArea.reset();
    this.isOpenPanelLock = false;
  }

  /** 実績解除 */
  private async onClickRelease() {
    this.submit();
    this.isOpenPanelLock = true;
    await this.registPanelArea.releaseResult();
    this.isOpenPanelLock = false;
  }

  /** 実績登録 */
  private async onClickSubmit(callback?: Function) {
    this.submit();
    this.isOpenPanelLock = true;
    await this.registPanelArea.registration(callback);
    this.isOpenPanelLock = false;
  }

  /** 成功時のメッセージを取得する */
  private getSuccessMessage(message: string) {
    if (message == "登録しました") {
      this.messageItem.registrationMessage = message;
    }
    if (message == "登録実績を解除しました") {
      this.messageItem.releaseConfirmedResultsMessage = message;
    }
    if (message == "リセットしました") {
      this.messageItem.resetMessage.message = message;
    }
  }
}
