import Base from "../base";
import { InquiryAnswer } from "./inquiryAnswer";
import { Staff } from "./../staff";
import { FileIF } from "./file";

export interface Inquiry extends Base {
  id: number; // ID
  status: number; // '問い合わせステータス\n1:未対応\n2:連絡とれず\n3:進行中\n9:対応完了',
  is_published: number; // 公開フラグ\n0:非公開\n1:公開
  agreement_id: number; // '契約ID',
  office_id: number; // '事業所ID',
  staff_id: number; // '問い合わせ者ID',
  staff_name: string; // 問い合わせ者名前
  staff_auth_id: number; // '問い合わせ者職員権限',
  inquiry_datetime: string; // '問い合わせ日時',
  inquiry_date: string; // '問い合わせ日',
  inquiry_time: string; // '問い合わせ時刻',
  hope_contact_datetime: string; // '希望連絡日時',
  inquiry_div: number; // '問い合わせ方法\n1:電話\n2:iBowフォーム\n3:営業経由\n4:その他',
  hope_contact_div: number; // '希望連絡方法\n0:—\n1:どちらでも\n2:電話\n3:メール',
  tel_no: string; // '電話番号',
  complete_datetime: string; // '回答完了日時',
  contact: string; // '問い合わせ内容'
  is_not_reading: number; // 未読フラグ
  admin_id: number; // 最終更新者ID
  version: number; // バージョン\n0:iBow2\n1:iBow
  is_access_confirmed: number; // 登録状況の確認希望 0:未選択 1:希望しない 2:希望する
  inquiry_datetime_wareki: string; // 問い合わせ日時（和暦）
  hope_contact_datetime_wareki: string; // 希望連絡日時（和暦）
  complete_datetime_wareki: string; // 回答完了日時（和暦）
  inquiry_answers: InquiryAnswer[]; // 問い合わせに対する回答集
  inquiry_contacts: InquiryContact[]; // コンタクト情報
  inquiry_files: InquiryFile[]; // 添付ファイル情報
  ticket_no: string; // チケット番号

  /** 追加問い合わせ */
  sub_inquiries: Inquiry[];
  inquiry_datetime_short: string; // 問い合わせ日時（時分まで）
  complete_datetime_short: string; // 回答完了日時（時分まで）
}

/* 問い合わせ - コンタクト履歴 */
export interface InquiryContact extends Base {
  id: number; //ID
  inquiry_id: number; //'問い合わせID',
  contact_info: string; //'コンタクト内容',
}

export interface InquiryFile extends Base, FileIF {
  /** ID */
  id: number;
  /** 問い合わせID */
  inquiry_id: number;
  /** 並び順 */
  sort_no: number;
  /** ファイルパス */
  file_path: string;
}

export const DEFAULT_INQUIRY: Inquiry = {
  id: 0, // ID
  status: 1, // '問い合わせステータス\n1:未対応\n2:連絡とれず\n3:進行中\n9:対応完了'
  is_published: 0, // 公開フラグ\n0:非公開\n1:公開
  agreement_id: 0, // '契約ID',
  office_id: 0, // '事業所ID',
  staff_id: 0, // '問い合わせ者ID',
  staff_name: "", // 問い合わせ者名前
  staff_auth_id: 0, // '問い合わせ者職員権限',
  inquiry_datetime: "", // '問い合わせ日時',
  inquiry_date: "", // '問い合わせ日',
  inquiry_time: "", // '問い合わせ時刻',
  hope_contact_datetime: "", // '希望連絡日時',
  inquiry_div: 4, // '問い合わせ方法\n1:電話\n2:iBowフォーム\n3:営業経由\n4:その他',
  hope_contact_div: 1, // '希望連絡方法\n0:—\n1:どちらでも\n2:電話\n3:メール',
  tel_no: "", // '電話番号',
  complete_datetime: "", // '回答完了日時',
  contact: "", // '問い合わせ内容',
  admin_id: 0,
  version: 0,
  is_access_confirmed: 0,
  is_not_reading: 0,
  inquiry_datetime_wareki: "", // 問い合わせ日時（和暦）
  hope_contact_datetime_wareki: "", // 希望連絡日時（和暦）
  complete_datetime_wareki: "", // 回答完了日時（和暦）
  inquiry_answers: [], // 問い合わせに対する回答集
  inquiry_contacts: [], // コンタクト情報
  inquiry_files: [],
  created_at: "",
  updated_at: "",
  deleted_at: "",
  ticket_no: "",
  sub_inquiries: [],
  inquiry_datetime_short: "",
  complete_datetime_short: ""
};

/** 表示用Inquiry */
export interface DispInquiry extends Inquiry {
  ticket_no: string;
  last_answered_datetime: string;
  co_code: string;
  kst_no: string;
  corresponding_admin_names: string;
  use_receipt_type: number;
  inquiry_date: string;
  inquiry_time: string;
  all_answer_contents: string;
  answer_statuses: number;
  divs: string;
  services: string;
  groups: string;
  admin_name: string;
}

export const DEFAULT_DISP_INQUIRY: DispInquiry = {
  ...DEFAULT_INQUIRY,
  ticket_no: "",
  last_answered_datetime: "",
  co_code: "",
  kst_no: "",
  corresponding_admin_names: "",
  use_receipt_type: 0,
  inquiry_date: "",
  inquiry_time: "",
  all_answer_contents: "",
  answer_statuses: 0,
  divs: "",
  services: "",
  groups: "",
  admin_name: ""
};

export const DEFAULT_INQUIRY_CONTACT: InquiryContact = {
  id: 0,
  inquiry_id: 0,
  contact_info: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
};

export const DEFAULT_INQUIRY_FILE: InquiryFile = {
  id: 0,
  inquiry_id: 0,
  sort_no: 1,
  file_path: "",
  path: "",
  comment: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
};

/* 問い合わせ区分 */
export interface InquiryDiv extends Base {
  /** ID */
  id: number;
  /** 並び順 */
  sort_no: number;
  /** 区分名 */
  name: string;
}

/* 問い合わせグループ */
export interface InquiryGroup extends Base {
  /** ID */
  id: number;
  /** 並び順 */
  sort_no: number;
  /** グループ名 */
  name: string;
}

/* 問い合わせサービス種類 */
export interface InquiryServiceDiv extends Base {
  /** ID */
  id: number;
  /** 並び順 */
  sort_no: number;
  /** サービス種類名 */
  name: string;
}

/** 問い合わせ職員 */
export interface InquiryOfficeStaff extends Staff {
  agreement_name: string;
}

/* CSアンケートマスタ */
export interface InquiryQuestionnaire extends Base {
  /** ID */
  id: number;
  /** マスタ名 */
  name: string;
  /** CSアンケート設問マスタ */
  questions: InquiryQuestionnaireQuestion[];
}

/* CSアンケート設問マスタ */
export interface InquiryQuestionnaireQuestion extends Base {
  /** ID */
  id: number;
  /** CSアンケートマスタID */
  inquiry_questionnaire_id: number;
  /** 設問種類 1:n段階評価設問 2:コメント単体設問 */
  question_type: number;
  /** 設問文章 */
  question_text: string;
  /** 並び順 */
  sort_no: number;
  /** CSアンケート選択肢マスタ */
  selections: InquiryQuestionnaireSelection[];
}

/* CSアンケート選択肢マスタ */
export interface InquiryQuestionnaireSelection extends Base {
  /** ID */
  id: number;
  /** CSアンケート設問マスタID */
  inquiry_questionnaire_question_id: number;
  /** 選択肢文章 */
  selection_text: string;
  /** 選択肢値 */
  selection_value: number;
  /** 並び順 */
  sort_no: number;
}

export const DEFAULT_INQUIRY_QUESTIONNAIRE: InquiryQuestionnaire = {
  id: 0,
  name: "",
  questions: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
};
